/* ISSUE: Ensure that all information conveyed with color is also available without color */

/* /core/public/shared/assets/css/responsive.css */
.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	text-decoration: line-through;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-unthemed .fc-popover {
	opacity: 0.9;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
	cursor: pointer;
}

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	cursor: pointer;
}

.fc-event,
.fc-event-dot {
	cursor: pointer;
}

.fc-unthemed .fc-list-empty {
	opacity: 0.9;
}

/* /core/website/modules/events/css/screen.css */
#events_module.cms_list .cms_button:hover {
	cursor: pointer;
}

#events_module.cms_list .cms_oddRow {
	border-bottom: 1px solid #ddd;
}

#events_module.cms_form .cms_button:hover {
	cursor: pointer;
}

#events_module.cms_list .fc-list-item:hover td .fc-event-dot[style="background-color:#FFFFFF"] {
	cursor: pointer;
}

.full_calendar_view .fc-state-active {
	cursor: pointer;
}

#events_module.homepage_contentbox.conference-tabs .conference-heading.tabbed.current {
	cursor: pointer;
}

#events_module.homepage_contentbox.conference-tabs .tab-heading-text {
	font-weight: 600;
}

/* /core/website/modules/submissionforms/css/screen.css */
#submissionforms_module.cms_form .cms_steps_wrapper .active,
#submissionforms_module.cms_form .cms_steps_wrapper .last_active {
	font-weight: bold;
}

#submissionforms_module.cms_form .container-buttons-wrapper span.container-button:hover,
#submissionforms_module.homepage_contentbox .container-buttons-wrapper span.container-button:hover {
	cursor: pointer;
}

#submissionforms_module.cms_form .container-buttons-wrapper span.add:hover,
#submissionforms_module.homepage_contentbox .container-buttons-wrapper span.add:hover {
	cursor: pointer;
}

#submissionforms_module.cms_form .container-buttons-wrapper span.remove:hover,
#submissionforms_module.homepage_contentbox .container-buttons-wrapper span.remove:hover {
	cursor: pointer;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover {
	cursor: pointer;
}

#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button:hover {
	cursor: pointer;
}

#submissionforms_module.cms_form .cms_steps_wrapper span a {
	cursor: pointer;
}

#submissionforms_module.cms_form .required_information em {
	font-style: italic;
}

#submissionforms_module.cms_form .cms_required em {
	font-style: italic;
}

#submissionforms_module.homepage_contentbox .required_information em {
	font-style: italic;
}

#submissionforms_module.homepage_contentbox .cms_label em {
	font-style: italic;
}


/* ISSUE: Removing the underline from links makes it hard for color-blind users to see them */

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-list-item-title a {
	text-decoration: underline;
}

/* ISSUE: Ensure that foreground and background colors have enough contrast */

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-list-container ul.fly-out-menu-list li a:active,
.fly-out-menu-list-container ul.fly-out-menu-list li.selected a {
	background: #1f78d1 !important;
	color: #ffffff !important;
	cursor: pointer;
}

/* /core/public/shared/assets/css/screen.css */
.search-facets .search-facet .price-range-button a {
	background-color: #757575;
	color: #ffffff;
	cursor: pointer;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-basic-view .fc-day-top .fc-week-number {
	text-align: center;
	background-color: #f2f2f2;
	color: #6e6e6e;
}

/* /core/website/modules/events/css/screen.css */
#events_module.cms_list div.events_module_calendar .fc-view-container .fc-event {
	font-size: 0.75rem;
	background-color: #757575;
	color: #000000;
}

/* ISSUE: Use relative rather than absolute units in CSS property values */

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-container .fly-out-menu-button .glyph-keyboard_arrow_down {
	font-size: 1.25rem;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
	line-height: 0.5rem;
	font-size: 0.75rem;
}

/* /core/website/modules/newsletters/css/screen.css */
#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a:link,
#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a:active,
#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a:visited {
	font-size: 0.75rem;
}

#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a:hover,
#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a.selected,
#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a.selected:hover {
	font-size: 0.75rem;
}

#newsletters_module.homepage_contentbox.displaymode_list_with_issues .list_item .metadata2.date {
	font-size: 0.6875rem;
	line-height: 0.875rem;
}

#newsletters_module.homepage_contentbox.displaymode_list_with_issues .list_item .metadata1.title a {
	font-size: 0.6875rem;
	line-height: 0.875rem;
}

#newsletters_module.homepage_contentbox.displaymode_list_with_issues .footer a {
	font-size: 0.625rem;
	line-height: 0.6875rem;
}

/* /core/website/modules/submissionforms/css/print.css */
.module-forms.confirmation .step-title {
	font-size: 0.875rem;
}

/* /core/website/modules/submissionforms/css/screen.css */
#submissionforms_module.cms_form .cms_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms.confirmation .step-title {
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox .cms_hint,
#submissionforms_module.homepage_contentbox .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms .ui-form-field-payment-information,
.module-forms .ui-data-addresses h3 {
	font-size: 0.875rem;
}
