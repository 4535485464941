body {
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.page-wrapper {
	font-family: 'Open Sans', sans-serif;
	color: #636363;
}

#printHeader,
#printFooter {
	display: none;
}

h1 {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	color: #1c5ba2;
	font-size: 2.35rem;
	line-height: 2.375rem;
	font-weight: 700;
}

h2 {
	margin-top: 1rem;
	margin-bottom: 0.75rem;
	color: #1c5ba2;
	font-size: 1.875rem;
	line-height: 2.125rem;
	font-weight: 700;
}

h3 {
	margin-top: 1rem;
	margin-bottom: 0.75rem;
	color: #1c5ba2;
	font-size: 1.5rem;
	line-height: 1.75rem;
	font-weight: 700;
}

h4 {
	margin-top: 1rem;
	margin-bottom: 0.75rem;
	color: #1c5ba2;
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 700;
}

h5 {
	margin-top: 1rem;
	margin-bottom: 0.75rem;
	color: #0e3669;
	font-size: 0.9375rem;
	line-height: 1.35rem;
	font-weight: 700;
}

h5 {
	margin-top: 1.375rem;
	margin-bottom: 0.75rem;
	color: #1c5ba2;
	font-size: 1.05rem;
	line-height: 1.45rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-transform: none;
}

h6 {
	margin-top: 1rem;
	margin-bottom: 0.75rem;
	color: #1c5ba2;
	font-size: 0.925rem;
	line-height: 1.25rem;
	font-weight: 700;
	text-transform: none;
}

h6 {
	margin-top: 1.5rem;
	margin-bottom: 0.875rem;
	color: #0c3467;
	font-size: 0.8125rem;
	line-height: 1.2rem;
	font-weight: 400;
	letter-spacing: 0.01rem;
}

p {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	color: #636363;
}

body a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #288ab1;
	text-decoration: none;
}

li {
	margin-bottom: 0.25rem;
}

blockquote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1.5rem 1.75rem;
	border-left: 5px solid #e2e2e2;
	color: #606060;
	font-size: 1.35rem;
	line-height: 1.95rem;
}

.button,
.primary,
.secondary,
.tertiary,
button[type="submit"] {
	display: inline-block;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 0.5rem;
	padding: 8px 16px 9px;
	border: 2px solid #1c5ba2;
	background-color: #1c5ba2;
	color: #fff;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	border-radius: 0;
	-webkit-appearance: none;
}

.primary,
.secondary,
.tertiary,
button[type="submit"] {
	padding: 8px 16px 9px !important;
	border: 2px solid #1c5ba2 !important;
}

.left-sidebar-custom a.primary,
.main-content-column-nested a.primary,
.right-sidebar-column a.primary,
.left-sidebar-custom a.secondary,
.main-content-column-nested a.secondary,
.right-sidebar-column a.secondary,
.left-sidebar-custom a.tertiary,
.main-content-column-nested a.tertiary,
.right-sidebar-column a.tertiary {
	color: #fff;
	font-weight: 600;
}

.button:hover,
.primary:hover,
.secondary:hover,
.tertiary:hover,
button[type="submit"]:hover {
	border-color: #1c5ba2 !important;
	background-color: #fff !important;
	color: #1c5ba2;
}

.button.secondary {
	padding-top: 8px;
	border: 2px solid #009770;
	background-color: #009770;
	color: #fff;
	text-align: center;
}

.secondary,
button[type="submit"].secondary {
	border: 2px solid #009770 !important;
	background-color: #009770;
}

.button.secondary:hover,
.secondary:hover,
button[type="submit"].secondary:hover {
	border-color: #009770 !important;
	background-color: #fff !important;
	color: #009770;
}

.button.secondary.tender {
	width: 100%;
	height: 45px;
	max-width: 250px;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	padding-top: 9px;
	float: left;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.button.tertiary {
	height: 36px;
	padding: 4px 12px 5px;
	border-style: solid;
	border-color: #00a8e4;
	background-color: #00a8e4;
}

.button.tertiary,
.tertiary,
button[type="submit"].tertiary {
	padding: 4px 12px 5px 12px !important;
	border: 2px solid #00a8e4 !important;
	background-color: #00a8e4;
}

.button.tertiary:hover,
.tertiary:hover,
button[type="submit"].tertiary:hover {
	background-color: #fff !important;
	border-color: #00a8e4 !important;
	color: #00a8e4;
}

.main-content-column-nested .button.inquiry {
	width: 100%;
	height: 45px;
	max-width: 250px;
	margin-top: 0.5rem;
	padding-top: 9px;
	float: left;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	border-bottom: 2px solid #1c5ba2;
	font-weight: 600;
	color: #fff;
}

.main-content-column-nested .button.inquiry:hover {
	color: #1c5ba2;
	cursor: pointer;
}

.button.search-properties {
	display: block;
	width: 48%;
	max-width: 250px;
	min-width: 230px;
	margin-right: 1%;
	margin-left: 1%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-color: #0c366c;
	background-color: #0c366c;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	border-radius: 0px;
	-webkit-appearance: none;
	cursor: pointer;
}

.button.search-properties:hover {
	background-color: #fff;
	cursor: pointer;
}

.button.pop-up-details {
	width: 100%;
	height: 38px;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-top: 5px;
	padding-bottom: 5px;
}

.text-link,
.left-sidebar-custom a,
.main-content-column-nested a,
.right-sidebar-column a {
	border-bottom: 1px solid #d8d8d8;
	color: #0c366c;
	font-weight: 400;
	text-decoration: none;
	cursor: pointer;
}

.text-link:hover,
.left-sidebar-custom a:hover,
.main-content-column-nested a:hover,
.right-sidebar-column a:hover {
	border-bottom-color: transparent;
	color: #0068b4;
}

.header-link {
	display: inline;
	border-bottom: 1px none transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #1c5ba2;
	line-height: 1.3em;
	text-decoration: none;
}

.header-link:hover {
	border-bottom-style: solid;
	border-bottom-color: rgba(28, 91, 162, 0.6);
	opacity: 1;
	color: #009770;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.success {
	padding-top: 0.75rem;
	border-style: solid;
	border-width: 10px 1px 1px;
	border-color: #419f37;
	border-radius: 3px;
	background-color: #fff;
	color: #419f37;
}

.screen-message.error {
	padding-top: 0.75rem;
	border-style: solid;
	border-width: 10px 1px 1px;
	border-color: #ed1b24;
	border-radius: 3px;
	background-color: #fff;
	color: #ed1b24;
}

.main-content {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.colors {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.color-block {
	width: 25%;
	border: 3px solid #fff;
}

.color-with-hex-code {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 200px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 3px solid #fff;
	background-color: #0e3669;
	color: #fff;
}

.color-with-hex-code.teal-2 {
	height: 100px;
	border-style: none;
	background-color: #216183;
}

.color-with-hex-code.heading-blue {
	height: 100px;
	border-style: none;
	background-color: #0c3467;
	text-align: center;
	text-shadow: 1px 1px 50px #000;
}

.color-with-hex-code.blue-1 {
	height: 100px;
	border-style: none;
	background-color: #1a457c;
	color: #fff;
	text-shadow: 1px 1px 47px #000;
}

.color-with-hex-code.blue-2 {
	height: 100px;
	border-style: none;
	background-color: #325b8f;
}

.color-with-hex-code.blue-3 {
	height: 100px;
	border-style: none;
	background-color: #052650;
}

.color-with-hex-code.blue-4 {
	height: 100px;
	border-style: none;
	background-color: #162742;
}

.color-with-hex-code.blue-5 {
	height: 100px;
	border-style: none;
	background-color: #021733;
	color: #fff;
}

.color-with-hex-code.teal-1 {
	height: 100px;
	border-style: none;
	background-color: #288ab1;
}

.color-with-hex-code.orange-1 {
	height: 100px;
	border-style: none;
}

.color-with-hex-code.orange-2 {
	height: 100px;
	border-style: none;
	background-color: #9d3e06;
	color: #fff;
}

.color-with-hex-code.yellow-1 {
	height: 100px;
	border-style: none;
}

.color-with-hex-code.bright-blue-1 {
	height: 100px;
	border-style: none;
	background-color: #68b9e3;
}

.color-with-hex-code.neutral-1 {
	height: 100px;
	border-style: none;
	background-color: #ebebeb;
	color: #000;
}

.color-with-hex-code.neutral-2 {
	height: 100px;
	border-style: none;
	background-color: #d4d3c7;
	color: #000;
}

.color-with-hex-code.grey-1 {
	height: 100px;
	border-style: none;
	background-color: #f2f2f2;
	color: #000;
}

.color-with-hex-code.grey-2 {
	height: 100px;
	border-style: none;
	background-color: #c2c2c2;
	color: #000;
}

.color-with-hex-code.grey-3 {
	height: 100px;
	border-style: none;
}

.color-with-hex-code.grey-4 {
	height: 100px;
	border-style: none;
	background-color: #606060;
}

.color-with-hex-code.gold-1 {
	height: 100px;
	border-style: none;
	background-color: #e2d287;
	color: #000;
}

.color-with-hex-code.gold-2 {
	height: 100px;
	border-style: none;
	background-color: #d2b95b;
	color: #000;
}

.header-section {
	position: fixed;
	z-index: 1;
	width: 100%;
	top: 0;
}

.color-palette {
	display: none;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.branding-content-wrapper {
	float: left;
}

.logo-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 1rem;
	padding-bottom: 0.8rem;
	padding-left: 3.5rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	box-shadow: inset 18px 0 0 0 #999;
}

.nav-menu-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-left: 3.5rem;
	float: left;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.logo-image {
	width: 100%;
}

.dropdown-list {
	overflow: hidden;
}

.dropdown-list.w--open {
	background-color: hsla(0, 0%, 89%, 0.95);
	box-shadow: 0 1px 90px 0 rgba(0, 0, 0, 0.4);
}

.nav-link {
	width: 25%;
	margin-bottom: 0rem;
	padding: 0.85rem 1rem 1rem;
	border-bottom-style: none;
	box-shadow: none;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #fff;
	font-size: 0.98rem;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
}

.nav-link:hover {
	background-color: #0c366c;
	box-shadow: inset 0 -5px 0 0 #3f97de;
	color: #fff;
	font-weight: 600;
	text-transform: uppercase;
}

.nav-link.secondary {
	display: none;
	padding: 1rem;
}

.nav-link.secondary:hover {
	box-shadow: none;
}

.brand {
	width: 535px;
	margin-bottom: 5px;
	padding-left: 0px;
}

.header-menu-inside-wrap {
	display: block;
	width: 100%;
	max-width: 1000px;
}

.nav-container {
	position: relative;
}

.header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.nav-section {
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	font-family: 'Open Sans', sans-serif;
}

.nav-section.inside-page {
	background-color: #fff;
	background-image: none;
}

.menu-button {
	float: right;
	background-image: -webkit-linear-gradient(270deg, #142f6d, #142f6d);
	background-image: linear-gradient(180deg, #142f6d, #142f6d);
}

.menu-button:hover {
	background-image: -webkit-linear-gradient(270deg, #e11f26, #e11f26);
	background-image: linear-gradient(180deg, #e11f26, #e11f26);
}

.header-contents-wrap {
	position: relative;
	margin-bottom: 15px;
	background-color: transparent;
}

.header-contents-wrap.inside {
	margin-bottom: 0px;
}

.dropdown-nav-link {
	display: block;
	padding: 0.85rem 1rem 1rem;
	border-bottom-style: none;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #fff;
	font-size: 0.98rem;
	font-weight: 600;
	text-transform: uppercase;
}

.dropdown-nav-link.active-item {
	background-color: #009770;
	box-shadow: inset 0 -5px 0 0 #015d46;
}

.dropdown {
	width: 25%;
}

.dropdown:hover {
	color: #000;
	width: 25%;
}

.menu-text {
	margin-top: 0px;
	margin-bottom: -10px;
	color: #fff;
}

.dropdown-toggle {
	width: 100%;
	padding: 0px;
	box-shadow: none;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	font-size: 0.95rem;
	text-align: center;
}

.dropdown-toggle:hover {
	background-color: #0c366c;
	box-shadow: inset 0 -5px 0 0 #68b9e3;
	color: #fff;
}

.dropdown-toggle.w--open {
	background-color: #052650;
	box-shadow: inset 0 -5px 0 0 #68b9e3;
}

.social-media-link {
	display: block;
	margin-top: 4px;
	margin-right: 1rem;
	margin-bottom: 4px;
	padding-bottom: 0.75rem;
	float: left;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	border-bottom-style: none;
}

.social-media-link._2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40px;
	height: 40px;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-bottom: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.social-media-link._2.footer {
	height: 40px;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	padding: 0px 0px 0rem;
}

.social-media-link._3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40px;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-bottom: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.social-media-link._3.footer {
	height: 40px;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	padding-bottom: 0rem;
}

.social-media-link._1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40px;
	height: 40px;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-bottom: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.social-media-link._1.footer {
	height: 40px;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	padding: 0px 0px 0rem;
}

.dropdown-list-link {
	margin-bottom: 0rem;
	padding: 1rem;
	border-bottom-style: none;
	background-color: transparent;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #0c366c;
	font-size: 1.05rem;
	font-weight: 600;
}

.dropdown-list-link:hover {
	border-bottom-style: none;
	background-color: #fff;
	color: #1c5ba2;
}

.menu-wrapper-desktop {
	position: static;
	width: 100%;
	background-color: #1c5ba2;
	box-shadow: inset 0 -5px 0 0 #0c366c;
}

.social-media-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0px;
	padding: 0.5rem 1rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.red-nav-divider {
	display: none;
	border-top: 5px solid #e11f26;
	background-image: -webkit-linear-gradient(270deg, transparent, transparent);
	background-image: linear-gradient(180deg, transparent, transparent);
}

.facebook {
	margin-top: 0px;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.facebook:hover {
	width: 34px;
}

.hamburger-icon {
	padding-left: 3px;
	color: #fff;
	font-size: 1.75rem;
}

.form-block {
	display: block;
	width: 90%;
	margin: 1rem auto;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.form-block.option-1 {
	display: none;
}

.form-block.option-2 {
	display: none;
}

.spotlight-list-text {
	padding-right: 5px;
	padding-left: 0px;
	color: #606060;
	font-size: 1.05rem;
	text-align: left;
}

.form-text-field {
	width: 100%;
	height: 58px;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding: 6px 10px;
	color: #1a457c;
	font-size: 0.9rem;
}

.form-text-field:focus {
	background-image: -webkit-linear-gradient(270deg, #c7e9f9, #c7e9f9), -webkit-linear-gradient(3deg, #fff, hsla(0, 0%, 56%, 0.4));
	background-image: linear-gradient(180deg, #c7e9f9, #c7e9f9), linear-gradient(87deg, #fff, hsla(0, 0%, 56%, 0.4));
}

.form-text-field::-webkit-input-placeholder {
	color: #1a457c;
	opacity: 1;
}

.form-text-field:-ms-input-placeholder {
	color: #1a457c;
	opacity: 1;
}

.form-text-field::placeholder {
	color: #1a457c;
	opacity: 1;
}

.form-text-field.half {
	width: 100%;
	margin-top: 0rem;
	margin-bottom: 0rem;
	float: left;
	background-image: none;
	color: #1a457c;
}

.form-text-field.half::-webkit-input-placeholder {
	color: #1a457c;
	opacity: 1;
}

.form-text-field.half:-ms-input-placeholder {
	color: #1a457c;
	opacity: 1;
}

.form-text-field.half::placeholder {
	color: #1a457c;
	opacity: 1;
}

.form-text-field.half.rooms {
	height: 58px;
	margin-top: 0px;
	background-image: none;
}

.form-text-field.top {
	width: 100%;
	height: 58px;
	margin-top: 0rem;
	margin-bottom: 0rem;
	float: left;
	color: #1a457c;
}

.form-text-field.top::-webkit-input-placeholder {
	color: #1a457c;
	opacity: 1;
}

.form-text-field.top:-ms-input-placeholder {
	color: #1a457c;
	opacity: 1;
}

.form-text-field.top::placeholder {
	color: #1a457c;
	opacity: 1;
}

.form-text-field._2 {
	height: 44px;
}

.search-form-content-wrapper {
	max-width: 1200px;
	min-width: 880px;
	margin-right: 2rem;
	margin-left: 2rem;
	background-color: hsla(0, 0%, 100%, 0.8);
	box-shadow: inset 0 0 32px -7px #fff, 0 0 100px 0 rgba(96, 96, 96, 0.75);
}

.icon {
	color: #fff;
	font-size: 2.25rem;
	font-style: normal;
	font-weight: 600;
}

.search-form {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	z-index: 200;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	padding-bottom: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: left;
}

.spotlight-unordered-list {
	width: 100%;
	padding-right: 5px;
	padding-left: 0px;
	float: left;
}

.form-select-field {
	display: block;
	width: 100%;
	height: 58px;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding: 6px 10px;
	float: left;
	background-color: #fff;
	background-image: none;
	color: #1a457c;
	font-size: 0.9rem;
}

.form-select-field:focus {
	background-color: #f1f1f1;
	background-image: none;
}

.form-select-field._2 {
	height: 44px;
}

.search-heading {
	margin-top: 0rem;
	margin-bottom: 0rem;
	color: #325b8f;
	font-size: 1.5rem;
	line-height: 2.5rem;
	font-weight: 700;
	text-align: left;
	letter-spacing: 0.05rem;
	text-shadow: none;
}

.listing-price {
	width: 100%;
	padding-left: 0px;
	float: left;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	color: #0c366c;
	font-size: 1.65rem;
	line-height: 2.25rem;
	text-align: left;
}

.content-box-heading {
	margin-top: 0px;
	margin-bottom: 1rem;
	padding: 0.75rem 1rem;
	border-bottom: 3px none #e2e2e2;
	border-left: 3px solid #c5c5c5;
	color: #288ab1;
	font-size: 2rem;
	font-weight: 700;
}

.search-button {
	width: 25%;
	height: 40px;
	max-width: 150px;
	min-width: 100px;
	margin-top: 1rem;
	padding: 6px 10px;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border: 2px none #fff;
	background-color: #325b8f;
	box-shadow: 1px 2px 54px 0 hsla(0, 0%, 100%, 0.75);
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	line-height: 1.25rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
}

.search-button:hover {
	background-color: #288ab1;
	cursor: pointer;
}

.property-spotlight-section {
	border-top: 5px none #e2e2e2;
	background-color: #d4d3c7;
	background-image: -webkit-linear-gradient(270deg, hsla(0, 0%, 100%, 0.3), hsla(0, 0%, 100%, 0.3));
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.3), hsla(0, 0%, 100%, 0.3));
}

.property-spotlight-section._2 {
	background-color: #dadada;
	background-image: none;
}

.paragraph-cb-text p {
	width: 50%;
	margin-top: 20px;
	padding-right: 0.75rem;
	float: left;
	color: #4b4a4a;
	font-size: 1.1rem;
	line-height: 1.9rem;
	font-weight: 400;
}

.paragraph-cb-text.about-us {
	margin: 0 auto;
}

.paragraph-cb-text.about-us p {
	display: block;
	width: 100%;
	margin: 0.5rem auto 0rem;
	padding-right: 0rem;
	float: none;
	color: #606060;
	font-size: 1.25rem;
	line-height: 2.15rem;
	font-weight: 300;
	text-align: center;
}

.main-section {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-top: 132px;
	background-color: #ebebeb;
}

.main-section.full {
	padding-bottom: 0rem;
	background-color: #ebebeb;
}

.multi-slider-container {
	display: block;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	color: #091d49;
}

.multi-slider-container.footer {
	max-width: 1700px;
	padding-top: 0rem;
	padding-bottom: 0rem;
	border-top: 5px none #1c5ba2;
	background-color: rgba(0, 0, 0, 0.15);
}

.copyright-text {
	display: inline-block;
	width: 100%;
	margin-top: 0rem;
	margin-bottom: 0px;
	color: #fff;
	font-size: 0.85rem;
	text-transform: uppercase;
}

.privacy-legal-sitemap-links {
	margin-top: 0rem;
	margin-bottom: 5px;
	color: #fff;
	font-size: 0.85rem;
	text-transform: none;
}

@media (max-width: 767px) {
	.privacy-legal-sitemap-links {
		text-align: center;
	}

	.privacy-legal-sitemap-links span {
		display: inline-block;
	}
}

.contact-information {
	width: 100%;
}

.social-media-icon-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 5px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.social-media-icon-wrapper.footer {
	padding-bottom: 0px;
}

.footer-heading {
	width: 100%;
	margin-top: 0px;
	margin-left: 0px;
	color: #fff;
	font-size: 1.15rem;
	text-align: left;
}

.footer-heading.navigation {
	margin-bottom: 1rem;
	margin-left: 0px;
	padding-left: 0px;
	float: none;
	color: #fff;
	font-size: 1.15rem;
	text-align: center;
}

.footer-heading.social {
	margin-bottom: 1rem;
	margin-left: 0px;
	padding-left: 0px;
	float: none;
	color: #fff;
	font-size: 1.15rem;
	text-align: center;
}

.legal-link {
	border-bottom-style: none;
	color: #fff;
	text-decoration: none;
}

.legal-link:hover {
	color: #ff6f6f;
	cursor: pointer;
}

.privacy-link {
	border-bottom-style: none;
	color: #fff;
	text-decoration: none;
}

.privacy-link:hover {
	color: #ff6f6f;
	cursor: pointer;
}

.contact-info-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.iowa-realty-footer-link {
	border-bottom-style: none;
}

.copyright-info {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 0.75rem;
	padding-bottom: 1.25rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.copyright-info.tablet {
	display: none;
}

.footer-list-item {
	margin-right: 10px;
	margin-bottom: 0rem;
	padding-bottom: 1px;
	padding-left: 0px;
	line-height: 1.5rem;
}

.footer-list-item.one {
	margin-right: 10px;
	margin-bottom: 0rem;
	padding-right: 10px;
	padding-bottom: 1px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	font-size: 1.255rem;
}

.footer-link {
	padding-bottom: 2px;
	border-bottom: 1px solid #216183;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #c8e9ff;
	font-size: 0.9rem;
	line-height: 1.65rem;
	text-decoration: none;
	text-transform: capitalize;
}

.footer-link:hover {
	color: #fff;
	cursor: pointer;
}

.footer-section {
	position: relative;
	display: block;
	border-top: 5px solid #0057a5;
	background-color: #0c366c;
}

.unordered-list {
	width: 50%;
	float: left;
}

.unordered-list.two {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 35%;
	margin-bottom: 5px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.unordered-list.one {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 53%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.sitemap-link {
	border-bottom-style: none;
	color: #fff;
}

.sitemap-link:hover {
	color: #ff6f6f;
	cursor: pointer;
}

.contact-info-div {
	width: 50%;
	margin-bottom: 5px;
	color: #fff;
	font-size: 1rem;
	text-align: right;
}

.contact-info-div.directions {
	width: 100%;
	margin-bottom: 3px;
	padding-right: 0.5rem;
	color: #c8e9ff;
	font-size: 0.9rem;
	text-align: left;
}

.logo-footer-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 5px;
	margin-right: auto;
	margin-left: auto;
	float: left;
	text-align: center;
}

.footer-content-wrapper {
	padding-right: 20px;
	float: left;
}

.footer-content-wrapper.first {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0px;
	margin-left: 0px;
	padding-top: 2rem;
	padding-bottom: 2rem;
	padding-left: 2.65vw;
	float: left;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	color: #ffc447;
	font-size: 1.45rem;
	text-align: center;
	flex-grow: 1;
}

.footer-content-wrapper.second {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 2rem;
	padding-right: 0px;
	padding-bottom: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	min-width: 285px;
}

.footer-content-wrapper.third {
	padding-top: 2rem;
	padding-right: 0px;
	padding-bottom: 2rem;
	background-color: transparent;
	text-align: center;
}

.slider-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.search-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.search-div.scale {
	height: 116px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px none #cacaca;
	background-color: #fff;
}

.search-div.scale.third {
	width: 100%;
}

.search-div.scale.half {
	width: 100%;
	height: auto;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.search-div.scale.half.inside {
	margin-bottom: 0.5rem;
}

.search-div.search {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 0.5rem;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	text-align: center;
}

.search-div.third {
	width: 100%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.slide-nav-main {
	z-index: 300;
	width: 100%;
	max-width: 800px;
	font-size: 0.625rem;
}

.search-title-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0.5rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 2.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 4px solid #d6dae0;
	background-color: #fff;
}

.quicklinks-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 320px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.min-div {
	width: 16px;
	height: 16px;
	border-radius: 0.25rem;
	background-color: #747474;
}

.min-div._2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25px;
	height: 25px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-radius: 2.25rem;
	background-color: #325b8f;
}

.min-div._2.inside {
	background-color: #0057a5;
	border-radius: 2.25rem;
}

.max-div {
	width: 16px;
	height: 16px;
	border-radius: 0.25rem;
	background-color: #747474;
}

.max-div._2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25px;
	height: 25px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-radius: 2.25rem;
	background-color: #325b8f;
}

.max-div._2.inside {
	background-color: #0057a5;
	border-radius: 2.25rem;
}

.line-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 10px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(96, 96, 96, 0.2);
}

.div-line-sections {
	width: 20%;
	height: 10px;
	border-right: 1px solid rgba(96, 96, 96, 0.45);
	border-left: 1px solid rgba(96, 96, 96, 0.45);
}

.div-line-sections.first {
	border-left-style: none;
}

.div-line-sections.first.colored {
	height: 10px;
	background-color: #e2d287;
}

.div-line-sections.first.colored.inside {
	height: 10px;
	background-color: rgba(0, 168, 228, 0.5);
}

.div-line-sections.last {
	border-right-style: none;
}

.div-line-sections.colored {
	height: 10px;
	background-color: #e2d287;
}

.div-line-sections.colored.inside {
	height: 10px;
	background-color: rgba(0, 168, 228, 0.5);
}

.scale-wrapper {
	display: block;
	width: 100%;
	height: auto;
	padding: 6px 9px;
	border: 1px solid #ccc;
}

.min-max-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 3px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.min-text {
	color: #1a457c;
	font-size: 0.8rem;
	line-height: 1.15rem;
}

.min-text.inside {
	font-size: 0.8rem;
	color: #0c366c;
}

.max-text {
	color: #1a457c;
	font-size: 0.8rem;
	line-height: 1.15rem;
}

.max-text.inside {
	font-size: 0.8rem;
	color: #0c366c;
}

.line-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 0.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
	font-size: 0.5rem;
	line-height: 1rem;
	text-align: center;
}

.money-slider-symbol {
	display: none;
	font-size: 0.6rem;
}

.status-bar-div {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 32px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-image: -webkit-linear-gradient(0deg, transparent, hsla(0, 0%, 100%, 0.55));
	background-image: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.55));
	z-index: 1;
}

.sold-status-bar-div {
	position: relative;
	display: block;
	width: 25%;
	height: 32px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-image: -webkit-linear-gradient(0deg, transparent, hsla(0, 0%, 100%, 0.55));
	background-image: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.55));
	z-index: 1;
}

.listing-status-text {
	font-weight: 700;
	text-transform: uppercase;
}

.listing-detail-column-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.search-wrapper {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	z-index: 200;
	height: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.main-content-container {
	display: block;
	width: 100%;
	max-width: 1700px;
	margin-right: auto;
	margin-left: auto;
	background-color: hsla(55, 28%, 92%, 0.7);
}

.main-content-container.inside {
	padding: 3rem 12% 2rem;
}

.main-content-container.inside.full {
	max-width: 1300px;
	margin-top: 3rem;
	padding: 0rem 3rem;
	background-color: #fff;
}

.main-content-container.inside.full.vertical-search {
	max-width: 1250px;
	padding-right: 2.5rem;
	padding-left: 0rem;
}

.main-content-container.inside.left {
	max-width: 100%;
	padding-right: 3rem;
	padding-bottom: 0rem;
	padding-left: 3rem;
	background-color: #ebebeb;
}

.footer-column-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.main-content-wrapper {
	display: block;
	max-width: 1200px;
	min-height: 500px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 3rem;
	padding-left: 3rem;
}

.main-content-wrapper.inside-right {
	max-width: 940px;
}

.main-content-wrapper.inside-right .w-col-12 {
	padding-left: 0;
	padding-right: 0;
}

.footer-signoff-bar {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-right: 2rem;
	padding-left: 2rem;
	border-top: 5px solid #154b89;
	background-color: #1c5ba2;
}

.grip-web-dev-text {
	color: #dadada;
	opacity: 1;
}

.footer-signoff-grip-white {
	width: 130px;
	height: 28px;
	margin-top: 0.25rem;
	padding-left: 0;
	float: right;
	border-bottom-style: none;
	border-bottom-width: 0px;
	opacity: 0.9;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #606060;
	font-size: 0.5rem;
	line-height: 0.5625rem;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-signoff-grip-white:hover {
	opacity: 1;
	color: #606060;
}

#GRIPFooterLogo {
	padding-top: 0 !important;
}

#GRIPFooterLogoImg {
	margin-top: -9px !important;
	margin-left: 1px;
}

.privacy {
	padding-bottom: 2px;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
	color: #fff;
}

.privacy:hover {
	border-bottom-color: transparent;
}

.signoff-column.first {
	flex-grow: 1;
}

.legal {
	padding-bottom: 2px;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
	color: #fff;
}

.legal:hover {
	border-bottom-color: transparent;
	cursor: pointer;
}

.sitemap-l {
	padding-bottom: 2px;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
	color: #fff;
}

.sitemap-l:hover {
	border-bottom-color: transparent;
}

.about-us-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 66.66%;
	min-width: 725px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.linkedin {
	margin-top: 0px;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.linkedin:hover {
	width: 34px;
}

.instagram {
	margin-top: 0px;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.instagram:hover {
	width: 34px;
}

.about-us-div {
	display: block;
	width: 100%;
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-bottom: 2.5rem solid #e1e0d8;
	background-color: hsla(0, 0%, 100%, 0.7);
	background-image: none;
}

.about-us-div._2 {
	padding: 2rem 2rem 2.5rem;
	border-bottom-style: none;
	background-color: #ebebeb;
	background-image: none;
	text-align: left;
}

.about-us-div._1 {
	display: none;
}

.image-listing-text {
	width: 100%;
	height: 300px;
	object-fit: cover;
}

.image-listing-text.tile-vertical {
	height: 180px;
}

.price-heading {
	margin-bottom: 0.25rem;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	color: #1a457c;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 700;
}

.search-div-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.html-embed-add-this {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 300;
	display: none;
}

.slider-end-line {
	width: 2px;
	height: 45%;
	margin-right: 2px;
	margin-left: 2px;
	background-color: hsla(0, 0%, 100%, 0.7);
}

.hero-rotator-section-kburns {
	position: relative;
	width: 100%;
}

.hero-rotator-widget-kburns {
	width: 100%;
	height: 36vh;
	max-height: 750px;
	min-height: 650px;
}

.ken-burns-wrapper {
	position: absolute;
	left: -100px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	overflow: hidden;
	height: 100%;
	background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.2), transparent 8%), url('../images/example-bg.png');
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), transparent 8%), url('../images/example-bg.png');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.ken-burns-wrapper._1 {
	background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.25), transparent 10%), url('../images/home_01_1903x691.jpg');
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.25), transparent 10%), url('../images/home_01_1903x691.jpg');
}

.ken-burns-wrapper._2 {
	background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.25), transparent 10%), url('../images/home_02_1903x691.jpg');
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.25), transparent 10%), url('../images/home_02_1903x691.jpg');
}

.ken-burns-wrapper._3 {
	background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.25), transparent 10%), url('../images/home_03_1903x691.jpg');
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.25), transparent 10%), url('../images/home_03_1903x691.jpg');
	-webkit-perspective: 1000px;
	perspective: 1000px;
}

.ken-burns-wrapper._4 {
	background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.25), transparent 10%), url('../images/home_08_1903x691.jpg');
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.25), transparent 10%), url('../images/home_08_1903x691.jpg');
	-webkit-perspective: 1000px;
	perspective: 1000px;
}

.ken-burns-wrapper._5 {
	background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.25), transparent 10%), url('../images/home_05_1903x691.jpg');
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.25), transparent 10%), url('../images/home_05_1903x691.jpg');
	-webkit-perspective: 1000px;
	perspective: 1000px;
}

.ken-burns-wrapper._6 {
	background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.25), transparent 10%), url('../images/home_06_1903x691.jpg');
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.25), transparent 10%), url('../images/home_06_1903x691.jpg');
	-webkit-perspective: 1000px;
	perspective: 1000px;
}

.left-arrow-two {
	left: auto;
	top: auto;
	bottom: 0px;
	z-index: 300;
	width: 3.5rem;
	height: 3.5rem;
	opacity: 0.6;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
}

.left-arrow-two:hover {
	opacity: 1;
}

.right-arrow-two {
	left: auto;
	top: auto;
	right: 0px;
	bottom: 0px;
	z-index: 300;
	width: 3.5rem;
	height: 3.5rem;
	opacity: 0.6;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
}

.right-arrow-two:hover {
	opacity: 1;
}

.arrow-icon {
	font-size: 2rem;
}

.euro-min-and-max-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.euro-min-and-max-div.inside.vertical-search {
	padding-top: 0.25rem;
}

.scale-area-heading {
	margin-bottom: 0.25rem;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	color: #1a457c;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 700;
}

.scale-area-heading.inside {
	color: #1c5ba2;
	font-size: 0.9rem;
}

.euro-symbol-heading {
	margin-bottom: 0.25rem;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	color: #0c366c;
	font-size: 0.8rem;
	line-height: 1rem;
	font-weight: 400;
}

.area-symbol-heading {
	margin-bottom: 0.25rem;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	color: #0c366c;
	font-size: 0.8rem;
	line-height: 1rem;
	font-weight: 400;
}

.ken-burns-slide-1 {
	position: relative;
	overflow: hidden;
	-webkit-perspective: 1108px;
	perspective: 1108px;
	-webkit-perspective-origin: 50% 50%;
	perspective-origin: 50% 50%;
}

.ken-burns-slide-2 {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.ken-burns-slide-3 {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.rotator-search-wrap {
	position: relative;
}

.home-lower-content-wrap {
	display: block;
	max-width: 1700px;
	margin-right: auto;
	margin-left: auto;
	background-color: #ebebeb;
}

.phone-link {
	color: #c8e9ff;
	cursor: pointer;
}

.phone-link:hover {
	color: #fff;
	cursor: pointer;
}

.property-spotlight-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	background-image: -webkit-linear-gradient(270deg, transparent 36%, #fff 43%);
	background-image: linear-gradient(180deg, transparent 36%, #fff 43%);
}

.property-spotlight-wrapper.tile {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	border: 1px solid #e4e4e4;
}

.listing-details-column-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0.25rem 0.5rem 0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid rgba(96, 96, 96, 0.3);
	border-right: 1px solid rgba(96, 96, 96, 0.3);
	border-bottom: 1px solid rgba(96, 96, 96, 0.3);
}

.listing-details-column-2._3 {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-right-style: none;
}

.listing-details-column-2.first {
	width: 100%;
}

.prop-spotlight-details-wrapper {
	width: 100%;
	padding: 1rem 15px;
	background-color: #fff;
	height: auto;
}

.property-spotlight-slide.w-slide .spotlight-unordered-list .spotlight-list-text:first-child {
	min-height: 50px;
}

.prop-spotlight-details-wrapper.tile {
	min-height: 250px;
	border: 1px none #e4e4e4;
}

.listing-details-heading-2 {
	color: #606060;
	font-weight: 700;
}

.property-slider-arrow-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 62px;
	height: 62px;
	margin: auto 20px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50px;
	background-color: rgba(96, 96, 96, 0.5);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-size: 1.25rem;
}

.property-slider-arrow-link:hover {
	background-color: #fbb900;
	cursor: pointer;
}

.property-spotlight-slide {
	width: 93%;
	height: auto;
	margin-right: 1%;
	margin-left: 1%;
	padding-top: 0.75rem;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	background-color: transparent;
}

.listing-details-2 {
	width: 100%;
	color: #606060;
	text-align: center;
}

.property-slider-widget {
	height: 100%;
	background-color: #ebebeb;
	color: #000;
}

.property-spotlight-image-link {
	width: 100%;
	height: 300px;
	margin-bottom: 0rem;
	background-position: 50% 50%;
	background-size: 100%;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.property-spotlight-image-link:hover {
	border-bottom-color: #6dcdf4;
	background-size: 110%;
	opacity: 0.85;
}

.property-spotlight-image-wrapper {
	overflow: hidden;
	width: 100%;
	height: 100%;
	margin-top: -32px;
}

.property-spotlight-image-link:hover .property-spotlight-image-wrapper {
	height: 100%;
}

.property-spotlight-image-link .image-listing-text {
	-webkit-transition: transform 500ms ease;
	transition: transform 500ms ease;
}

.property-spotlight-image-link:hover .image-listing-text {
	transform: scale(1.1);
}

.property-spotlight-image-link.tile {
	height: 225px;
}

.property-spotlight-image-link.tile.vertical {
	height: 180px;
}

.property-spotlight-mask {
	display: block;
	margin-right: 80px;
	margin-left: 80px;
	padding-bottom: 3rem;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.property-slider-widget .owl-stage,
.property-slider-widget .owl-stage .owl-item {
	display: flex;
}

.listing-status-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 100%;
	min-width: 100px;
	margin-top: -0.5rem;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-left: 4px solid #000;
	background-color: #bd571a;
	color: #fff;
}

.listing-status-2.UnderAuction {
	border-left-color: #003463;
	background-color: #1c5ba2;
}

.listing-status-2.ForSale {
	border-left-color: #00699b;
	background-color: #0095db;
}

.listing-status-2.UnderOffer {
	border-left-color: #570057;
	background-color: #6a046a;
}

.listing-status-2.Sold {
	border-left-color: #b15400;
	background-color: #ee7100;
}

.listing-status-2.property-show.Sold {
	margin-top: 0;
}

.listing-status-2.ForRent {
	border-left-color: #006e2e;
	background-color: #009770;
}

.spotlight-list-wrapper-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.ken-burns-slide-4 {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.ken-burns-slide-5 {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.ken-burns-slide-6 {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.auction-date {
	width: 100%;
	margin-top: 0.75rem;
	margin-bottom: 0rem;
	padding-left: 0px;
	float: left;
	color: #0c3467;
	font-size: 1.25rem;
	line-height: 1.55rem;
	font-weight: 700;
	text-align: left;
}

.left-nav-list-item {
	margin-bottom: 0rem;
	box-shadow: none;
}

.blockquote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1.75rem 1.5rem;
	border-left-color: #009770;
	background-color: #f2f2f2;
	color: #636363;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-top: 2px none #498fa3;
}

.ordered-list.roman-order {
	list-style-type: lower-roman;
}

.ordered-list.alpha-order {
	list-style-type: lower-alpha;
}

.main-content-wrap.nested-content-wrap {
	margin-right: 0.5em;
}

.inside-content-background {
	display: block;
	max-width: 1250px;
	margin-right: auto;
	margin-left: auto;
	padding-bottom: 3rem;
	background-color: #fff;
}

.inside-content-background.full {
	padding-bottom: 3rem;
}

.inside-content-background.inside-left {
	padding-bottom: 3rem;
}

.inside-page-header-content-wrap {
	margin-bottom: 1.5rem;
	padding-top: 2rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.inside-page-header-content-wrap.right-sidebar {
	padding-top: 2rem;
}

.left-nav-nested-list-item {
	margin-bottom: 0px;
	box-shadow: none;
}

.left-nav-list-link {
	display: block;
	padding: 0.75rem 1.5rem 0.75rem 0.9rem;
	border-bottom: 1px solid #ebebeb;
	color: #0c366c;
	font-size: 0.97rem;
	line-height: 1.4rem;
	font-weight: 700;
	text-transform: none;
}

.left-nav-list-link:hover {
	background-color: hsla(0, 0%, 85%, 0.35);
	background-image: none;
	color: #0c366c;
	font-weight: 700;
}

.left-nav-list-link.selected {
	background-color: hsla(0, 0%, 85%, 0.35);
	color: #0c366c;
	cursor: pointer;
}

.left-nav-list-link.section-title {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	border-bottom-style: none;
	background-color: #009770;
	background-image: none;
	color: #fff;
	font-size: 1.25rem;
	line-height: 1em;
	font-weight: 700;
	text-transform: uppercase;
}

.left-nav-list-link.section-title:hover {
	color: #fff;
	cursor: pointer;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.left-nav-list-content-wrap {
	display: block;
	overflow: hidden;
	height: auto;
	margin-top: -1.5rem;
	margin-right: 0.5rem;
	padding-bottom: 1.5rem;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75em 1.5rem;
	border-style: none none solid;
	border-width: 2px 2px 1px;
	border-color: transparent transparent #ebebeb;
	color: #0c366c;
	font-size: 0.85rem;
	line-height: 1.375em;
	font-weight: 700;
	text-transform: none;
}

.left-nav-nested-list-link:hover {
	background-color: hsla(0, 0%, 85%, 0.35);
	background-image: none;
	font-weight: 700;
}

.left-nav-nested-list-link.selected {
	color: #5e8dbe;
	cursor: pointer;
}

.left-nav-nested-list-link.great-grandchild {
	padding-left: 2.5rem;
	font-size: 0.8rem;
}

.left-navigation-column {
	padding-left: 0px;
}

.breadcrumb-list {
	margin: 0rem 0.125rem 1rem;
	padding-left: 0px;
	font-size: 0.6875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.left-nav-list {
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	padding-left: 0px;
	box-shadow: none;
}

.intro_text_style,
.intro-text-style {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	color: #636363;
	font-size: 1.1875em;
	line-height: 1.5em;
}

.link {
	border-bottom: 1px solid transparent;
	color: #0c366c;
	font-weight: 600;
}

.link:hover {
	border-bottom-style: solid;
	border-bottom-color: rgba(12, 54, 108, 0.3);
	opacity: 0.75;
	color: #154b89;
}

.link.w--current {
	color: #28313b;
	font-weight: 700;
}

.link.breadcrumb-link {
	color: #606060;
	cursor: pointer;
}

.link.breadcrumb-link:hover {
	border-bottom-color: transparent;
}

.main-content-wrapper.inside-right {
	padding-right: 2rem;
	padding-left: 2rem;
}

.left-sidebar-custom h1,
.left-sidebar-custom h2,
.left-sidebar-custom h3,
.left-sidebar-custom h4,
.right-sidebar-column h1,
.right-sidebar-column h2,
.right-sidebar-column h3,
.right-sidebar-column h4,
.sidebar-section-title {
	margin-top: 0em;
	margin-bottom: 0.75em;
	color: #1c5ba2;
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 700;
}

.sidebar-list-item {
	margin-bottom: 0.75rem;
	padding-left: 0px;
}

.right-sidebar-column .box,
.sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.25rem;
	margin-left: 1rem;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.sidebar-list {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	padding-left: 0em;
}

.page-title {
	display: inline-block;
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-weight: 300;
}

.property-mortgate {
	display: none;
	margin-left: 5px;
	color: #288ab1;
	font-size: 1.3em;
}

.property-description {
	margin-bottom: 15px;
	padding: 20px 0px 25px;
	border-bottom: 1px solid #e4e4e4;
	font-size: 1.1em;
	line-height: 1.575em;
	font-weight: 300;
}

.property-disclaimer {
	padding-top: 10px;
	font-style: italic;
	font-weight: 300;
}

.property-offer-end-note {
	padding: 1.75rem 1.5rem;
	border-left: 5px solid #009770;
	background-color: #f2f2f2;
	color: #636363;
	text-align: center;
}

.listing-map-widget {
	display: none;
	height: 400px;
}

.property-slide-4 {
	background-image: url('../images/gallery-4.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.counter-p {
	margin-top: 0rem;
	margin-bottom: 0px;
	color: #ebebeb;
}

.property-details-slider {
	width: 75%;
	aspect-ratio: 4/3;
	max-height: 535px;
	height: unset;
}

.tool {
	width: 50px;
	height: 50px;
	margin-right: 10px;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
}

.tool.share {
	display: none;
	background-image: url('../images/icon-share_1icon-share.png');
	background-position: 50% 50%;
	background-size: 25px 25px;
	background-repeat: no-repeat;
}

.tool.print {
	display: none;
	min-height: 44px;
	min-width: 44px;
	margin-top: 15px;
	margin-left: 0px;
	padding-left: 10px;
	float: left;
	background-image: url('../images/icon-print_1icon-print.png');
	background-position: 50% 50%;
	background-size: 25px 25px;
	background-repeat: no-repeat;
	-webkit-transition: background-color 450ms ease;
	transition: background-color 450ms ease;
}

.tool.print:hover {
	background-color: #e4e4e4;
	background-image: url('../images/icon-print_1icon-print.png');
	background-position: 50% 50%;
	background-size: 25px 25px;
	background-repeat: no-repeat;
	box-shadow: none;
}

.category-value {
	margin-top: 0rem;
	margin-bottom: 0px;
}

.property-slide {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.property-price {
	margin-bottom: 1.25rem;
	color: #0c366c;
	font-size: 2.5rem;
	line-height: 2.7rem;
	font-weight: 400;
}

.estimated {
	color: #666;
	font-size: 0.7em;
}

.property-slide-1 {
	padding: 0px;
	background-image: url('../images/gallery-1.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.listing-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
	margin-bottom: 20px;
}

.container-2 {
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.container-2.listing-container {
	width: 100%;
	max-width: 1300px;
	min-height: 500px;
	padding-bottom: 0rem;
}

.container-2.listing-vertical-search-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1300px;
	min-height: 500px;
	padding-bottom: 0rem;
}

.listing-info {
	width: 32%;
	padding-left: 30px;
}

.listing-column {
	width: 30%;
	padding-right: 15px;
	padding-left: 15px;
	float: left;
}

.listing-column.new {
	width: 33.33%;
	min-width: 240px;
	padding-top: 1rem;
	padding-right: 0px;
	padding-left: 0rem;
	float: left;
	border-right: 1px none #e4e4e4;
}

.listing-column._2nd {
	width: 33.33%;
	min-width: 240px;
	padding-top: 1rem;
	padding-right: 0px;
	padding-left: 0rem;
	float: left;
	border-right: 1px solid #e4e4e4;
	border-left: 1px solid #e4e4e4;
	background-color: hsla(0, 0%, 85%, 0.15);
}

.property-details {
	margin-bottom: 0px;
}

.counter {
	position: absolute;
	left: 50%;
	right: auto;
	bottom: 0px;
	z-index: 1;
	display: block;
	width: 100%;
	padding: 5px 10px;
	background-color: rgba(0, 0, 0, 0.25);
	background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.5), transparent);
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), transparent);
	-webkit-transform: translate(-50%, 0px);
	-ms-transform: translate(-50%, 0px);
	transform: translate(-50%, 0px);
	color: #fff;
	font-size: 0.9em;
}

.property-slide-5 {
	background-image: url('../images/gallery-5.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.listings-tools {
	margin-top: 0.75rem;
}

.gallery-arrow {
	width: 50px;
	font-size: 1.25rem;
}

.tab-section {
	padding-top: 1rem;
	padding-bottom: 2rem;
}

.tab-content-1,
.tabs-content-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.main-content-column-nested .tab-link-new {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 250px;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-right: 8px none #0057a5;
	border-bottom: 6px solid #0057a5;
	background-color: #0068b4;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
}

.main-content-column-nested .tab-link-new:hover {
	border-right: 8px none #0095db;
	border-bottom-color: #0095db;
	background-color: #0057a5;
	color: #fff;
}

.main-content-column-nested .tab-link-new.w--current {
	max-width: 250px;
	padding: 0px;
	border-right: 10px none #68b9e3;
	border-bottom-color: #0077b7;
	background-color: #0095db;
	color: #fff;
}

.tabs-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 52px;
	float: left;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	text-align: center;
}

.tabs-content {
	width: 100%;
	float: left;
	border: 1px solid #e4e4e4;
	background-color: #fff;
}

.tabs-new {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.properties-div {
	width: 75%;
	padding-top: 1.5rem;
	padding-left: 2.5rem;
}

.div-button-wrapper {
	display: block;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.search-container {
	display: block;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-bottom: 0px;
	margin-left: auto;
	padding: 2.5rem 2.25rem 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: hsla(0, 0%, 100%, 0.5);
}

.search-container.property-search {
	display: block;
	background-color: transparent;
}

.inside-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.inside-search-field {
	width: 16.667%;
	margin-bottom: 0px;
	padding-top: 6px;
	padding-bottom: 6px;
	color: #1a457c;
}

.inside-search-field::-webkit-input-placeholder {
	color: #1a457c;
	opacity: 1;
}

.inside-search-field:-ms-input-placeholder {
	color: #1a457c;
	opacity: 1;
}

.inside-search-field::placeholder {
	color: #1a457c;
	opacity: 1;
}

.select-field {
	width: 16.667%;
	margin-bottom: 0px;
	background-color: #fff;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	color: #0c366c;
}

.property-tile-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.property-tile-div.less-tiles {
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
}

.property-spotlight-tile {
	width: 32%;
	height: auto;
	margin-right: 0.25%;
	margin-left: 0.25%;
	padding-top: 0.75rem;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	background-color: transparent;
	background-image: none;
}

.property-tile-div.less-tiles .property-spotlight-tile {
	margin-right: 1.5%;
}

.multi-map-div {
	position: relative;
	width: 100%;
	height: auto;
	margin-bottom: 2rem;
	opacity: 1;
}

.results {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: #606060;
	font-size: 0.85rem;
}

.pagination-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1.5rem;
	padding-bottom: 0.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.center-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.next-link-text {
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #1c5ba2;
}

.next-link-text:hover {
	color: #288ab1;
	cursor: pointer;
}

.previous-link-text-inactive {
	color: #909090;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.pagination-page-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30px;
	height: 30px;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid #ebebeb;
	background-color: hsla(0, 0%, 100%, 0.5);
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #0c366c;
	font-weight: 700;
}

.pagination-page-link:hover {
	background-color: rgba(0, 0, 0, 0.08);
	cursor: pointer;
}

.dot-dot-dot {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30px;
	height: 30px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	color: #909090;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.2rem;
}

.search-button-wtap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 600px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.mip-mock-div {
	position: absolute;
	z-index: 2;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 450px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.mock-pin-region {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 10%;
	height: 25%;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._2 {
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._3 {
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._4 {
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._5 {
	background-position: 27px 28px;
}

.mock-pin-region._6 {
	background-position: 16px 37px;
}

.mock-pin-region._7 {
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._8 {
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._9 {
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._10 {
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._11 {
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._12 {
	background-position: 100% 105%;
}

.mock-pin-region._13 {
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._14 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.mock-pin-region._15 {
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._16 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.mock-pin-region._18 {
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._19 {
	background-position: 15px 0px;
}

.mock-pin-region._20 {
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._22 {
	padding-top: 21px;
	padding-left: 16px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.mock-pin-region._23 {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.mock-pin-region._24 {
	background-position: 42px 44px;
}

.mock-pin-region._25 {
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._26 {
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._28 {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.mock-pin-region._30 {
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._31 {
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._32 {
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._36 {
	background-position: 0px 39px;
}

.mock-pin-region._37 {
	background-position: -7px 1px;
}

.mock-pin-region._38 {
	margin-top: 1px;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._39 {
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.mock-pin-region._40 {
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.pin-link._1 {
	margin-top: 7px;
	margin-left: 19px;
}

.pin-link._3 {
	margin-top: 25px;
}

.pin-link._4 {
	margin-left: 7px;
}

.pin-link._7 {
	margin-top: 30px;
}

.pin-link._11 {
	margin-top: 16px;
}

.pin-link._13 {
	margin-top: 5px;
	padding-left: 5px;
}

.pin-link._14 {
	padding-right: 20px;
	padding-bottom: 3px;
	padding-left: 0px;
}

.pin-link._15 {
	margin-right: -2px;
	padding-left: 65px;
}

.pin-link._16 {
	padding-bottom: 45px;
}

.pin-link._17 {
	margin-top: 54px;
}

.pin-link._18 {
	padding-left: 11px;
}

.orange-pin-image {
	width: 40px;
}

.pin-selection-pop-up-details {
	position: absolute;
	right: 44px;
	bottom: 29px;
	z-index: 3;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 0px;
	height: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	opacity: 0;
}

.pselction-pop-up-square {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 1rem 1rem 1rem 1.25rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	border: 1px solid #ccc;
	background-color: #fff;
	box-shadow: inset 6px 0 0 0 #288ab1, 0 0 65px 0 rgba(0, 0, 0, 0.25);
}

.pop-up-image {
	height: 125px;
	background-image: url('../images/home_08_1903x691.jpg');
	background-position: 0px 0px;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.25);
}

.pop-up-text-wrapper {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.title-button-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 0.75%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.button-map-list-wrap {
	margin-right: 1rem;
	margin-left: 1rem;
}

.map-view-icon-inactive {
	width: 100%;
}

.map-view-icon-link {
	width: 50px;
	padding: 0.35rem;
	-webkit-transition: background-color 450ms ease;
	transition: background-color 450ms ease;
}

.map-view-icon-link:hover {
	background-color: #ebebeb;
	cursor: pointer;
}

.list-view-icon-link {
	width: 50px;
	padding: 0.35rem;
	-webkit-transition: background-color 450ms ease;
	transition: background-color 450ms ease;
}

.list-view-icon-link:hover {
	cursor: pointer;
	background-color: #ebebeb;
}

.page-title-properties {
	display: inline-block;
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-weight: 300;
}

.list-view-icon-active {
	width: 100%;
}

.map-view-icon-active {
	display: none;
	width: 100%;
}

.list-view-icon-inactive {
	display: none;
	width: 100%;
}

.form-block-styles {
	margin-bottom: 0px;
}

.vertical-search-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	border: 1px solid #ebebeb;
}

.main-content-column-nested .label-section-title {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 0px;
	padding: 0.45rem 1rem;
	border-left: 4px solid #0077b7;
	border-bottom-width: 0 !important;
	background-color: hsla(0, 0%, 85%, 0.49);
	background-image: url('../images/search-icons-minus-green-07.svg');
	background-position: 95% 50%;
	background-size: 15px 15px;
	background-repeat: no-repeat;
	box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05), inset 0 1px 0 0 rgba(0, 0, 0, 0.05);
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #0c366c;
	font-weight: 600;
}

.main-content-column-nested .label-section-title:hover {
	background-color: #00a583;
	background-image: url('../images/search-icons-minus.svg');
	background-size: 15px 15px;
	color: #fff;
}

.main-content-column-nested .label-section-title.opened {
	background-image: url('../images/search-icons-plus-green.svg');
}

.main-content-column-nested .label-section-title.opened:hover {
	background-image: url('../images/search-icons-plus-white.svg');
}

.property-type-list-div {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-linear-gradient(180deg, hsla(0, 0%, 85%, 0.08), #fff);
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-field-label {
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.single-field {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.single-field.property {
	padding-top: 0.15rem;
	padding-bottom: 0.15rem;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.single-field.name {
	padding-top: 0.15rem;
	padding-bottom: 0.15rem;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.single-field.half {
	padding-top: 0.15rem;
	padding-bottom: 0.15rem;
	padding-left: 0rem;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.single-field.third {
	padding-top: 0.15rem;
	padding-bottom: 0.15rem;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.property-type-field-label {
	margin-bottom: 0px;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.vertical-search-checkbox {
	margin-top: 0px;
	margin-left: 0px;
	background-color: #fff;
}

.radio-search-button {
	margin-top: 0px;
	margin-left: 0px;
}

.single-radio-field-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-left: 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.radio-field-label {
	margin-bottom: 0px;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.single-text-field {
	width: 100%;
	height: 100%;
	margin-bottom: 0px;
	padding-top: 6px !important;
	padding-bottom: 6px !important;
	border-radius: 0.5rem !important;
	font-size: 0.825rem !important;
	line-height: 1.25rem !important;
}

.price-min-input {
	width: 45%;
	height: 100%;
	min-width: 95px;
	margin-bottom: 0px;
	padding-top: 6px;
	padding-bottom: 6px;
	border-radius: 0.5rem;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.min-and-max-vertical-search-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 0.35rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.dash-text-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 32px;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 1.5rem;
}

.area-min-input {
	width: 45%;
	height: 100%;
	min-width: 95px;
	margin-bottom: 0px;
	padding-top: 6px;
	padding-bottom: 6px;
	border-radius: 0.5rem;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.price-max-input {
	width: 45%;
	height: 100%;
	min-width: 95px;
	margin-bottom: 0px;
	padding-top: 6px;
	padding-bottom: 6px;
	border-radius: 0.5rem;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.area-maximum-input {
	width: 45%;
	height: 100%;
	min-width: 95px;
	margin-bottom: 0px;
	padding-top: 6px;
	padding-bottom: 6px;
	border-radius: 0.5rem;
}

.all-heading-buttons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.clear-all-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 1rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.main-content-column-nested .clear-all-link {
	display: block;
	width: 100%;
	padding: 0.5rem 1rem;
	border: 2px solid #00a583;
	background-color: transparent;
	color: #009770;
	font-weight: 600;
	cursor: pointer;
	-webkit-appearance: none;
	border-radius: 0;
	background-image: none;
	text-align: center;
}

.main-content-column-nested .clear-all-link:hover {
	border-color: #666;
	background-color: #666;
	background-image: none;
	color: #fff;
}

.main-content-column-nested .search-link {
	display: block;
	width: 100%;
	padding: 0.5rem 1rem;
	border: 2px solid #009770;
	background-color: #009770;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	-webkit-appearance: none;
	border-radius: 0;
	background-image: none;
}

.main-content-column-nested .search-link:hover {
	border-color: #666;
	background-color: #666;
	background-image: none;
	color: #fff;
}

.vertical-search-form {
	width: 100%;
	margin-bottom: 0px;
	display: block;
	position: relative;
}

.vertical-search-form-div {
	width: 100%;
}

.tags-and-results-div {
	margin-left: 0.75%;
}

.tags-wrapper {
	display: none;
	width: 100%;
	padding-bottom: 0.5rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.tag-link {
	margin-top: 0.5rem;
	margin-right: 0.5rem;
	padding: 0.3rem 3rem 0.3rem 0.75rem;
	border: 2px solid #c8e9ff;
	border-radius: 0.75rem;
	background-color: transparent;
	background-image: url('../images/search-icons-x-arrow-grey-04.svg');
	background-position: 90% 50%;
	background-size: 10px;
	background-repeat: no-repeat;
	color: #1a2d49;
	font-size: 0.8rem;
	font-weight: 600;
}

.properties-details-div {
	width: 100%;
	padding-top: 1.5rem;
}

.property-overview-details-title {
	margin-top: 0rem;
	margin-bottom: 0.25rem;
}

.search-filter-heading {
	width: 100%;
	padding: 0.35rem 1rem;
	color: #1c5ba2;
	font-size: 1.46rem;
	line-height: 2.5rem;
	font-weight: 600;
}

.search-heading-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.property-type-list-div-2 {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-linear-gradient(180deg, hsla(0, 0%, 85%, 0.08), #fff);
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-list-div-2.property-subtype {
	background-image: none;
}

.property-type-list-div-3 {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-linear-gradient(180deg, hsla(0, 0%, 85%, 0.08), #fff);
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-list-div-3.beds {
	padding-left: 1rem;
}

.property-type-list-div-4 {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-linear-gradient(180deg, hsla(0, 0%, 85%, 0.08), #fff);
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-list-div-4.baths,
.property-type-list-div-4.planning-types {
	padding-left: 1rem;
}

.property-type-list-div-5 {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-linear-gradient(180deg, hsla(0, 0%, 85%, 0.08), #fff);
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-list-div-5.pool {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.property-type-list-div-6 {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-linear-gradient(180deg, hsla(0, 0%, 85%, 0.08), #fff);
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-list-div-7 {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-linear-gradient(180deg, hsla(0, 0%, 85%, 0.08), #fff);
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-list-div-8 {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-linear-gradient(180deg, hsla(0, 0%, 85%, 0.08), #fff);
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-list-div-9 {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-linear-gradient(180deg, hsla(0, 0%, 85%, 0.08), #fff);
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-list-div-9.status {
	padding: 0.75rem 1rem;
}

.property-type-list-div-10 {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-linear-gradient(180deg, hsla(0, 0%, 85%, 0.08), #fff);
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-list-div-10.property-id {
	padding: 0.75rem 1rem;
}

.property-type-list-div-11 {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-linear-gradient(180deg, hsla(0, 0%, 85%, 0.08), #fff);
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-list-div-11.price-range {
	padding: 0.75rem 1rem 0.5rem;
}

.property-type-list-div-12 {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-linear-gradient(180deg, hsla(0, 0%, 85%, 0.08), #fff);
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-list-div-12.area {
	padding: 0.75rem 1rem 0.5rem;
}

.listing-title {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 0px;
	padding: 0.25rem 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-right: 1px none #e4e4e4;
	border-bottom: 1px solid #e4e4e4;
}

.listing-title:last-child {
	margin-bottom: 0px;
	border-bottom-style: none;
}

.listing-title.edge {
	border-right-style: none;
}

.listing-section-title {
	margin-top: 0rem;
	margin-right: 0.5rem;
	margin-bottom: 0px;
	font-weight: 700;
}

.footer-container {
	display: block;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	color: #091d49;
}

.footer-container.footer {
	max-width: 1700px;
	padding-top: 0rem;
	padding-bottom: 0rem;
	border-top: 5px none #1c5ba2;
	background-color: rgba(0, 0, 0, 0.15);
}

html.w-mod-js *[data-ix="new-interaction"] {
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
}

html.w-mod-js *[data-ix="brand-load"] {
	opacity: 0;
	-webkit-transform: translate(-500px, 0px);
	-ms-transform: translate(-500px, 0px);
	transform: translate(-500px, 0px);
}

html.w-mod-js *[data-ix="search-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 200px);
	-ms-transform: translate(0px, 200px);
	transform: translate(0px, 200px);
}

html.w-mod-js *[data-ix="intro-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 200px);
	-ms-transform: translate(0px, 200px);
	transform: translate(0px, 200px);
}

html.w-mod-js *[data-ix="minislideshow-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 200px);
	-ms-transform: translate(0px, 200px);
	transform: translate(0px, 200px);
}

.phone-link {
	display: none;
}

@media (max-width: 991px) {
	h1 {
		font-size: 2.25rem;
		line-height: 2.275rem;
	}

	h2 {
		font-size: 1.775rem;
		line-height: 2.025rem;
	}

	h3 {
		font-size: 1.35rem;
		line-height: 1.7rem;
	}

	.left-sidebar-custom h1,
	.left-sidebar-custom h2,
	.left-sidebar-custom h3,
	.left-sidebar-custom h4,
	.right-sidebar-column h1,
	.right-sidebar-column h2,
	.right-sidebar-column h3,
	.right-sidebar-column h4,
	h4 {
		font-size: 1.2rem;
		line-height: 1.45rem;
	}

	h5 {
		font-size: 1rem;
		line-height: 1.4rem;
	}

	h6 {
		font-size: 0.9rem;
		line-height: 1.2rem;
	}

	.button.secondary.tender {
		width: 100%;
		max-width: 250px;
		margin-right: 0rem;
	}

	.main-content-column-nested .button.inquiry {
		width: 100%;
		max-width: 250px;
		margin-right: 1rem;
	}

	.main-content {
		width: 100%;
	}

	.branding-content-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 90%;
		float: left;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.logo-wrapper {
		width: 100%;
		padding-top: 0rem;
		padding-bottom: 0rem;
		padding-left: 2rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.nav-menu-wrap {
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.logo-image {
		float: none;
	}

	.nav-link {
		width: 100%;
		height: 60px;
		padding-top: 1rem;
		padding-bottom: 1.25rem;
		padding-left: 2rem;
		background-image: url('../images/nav-arrow-icon-01.svg');
		background-position: 97% 45%;
		background-size: 25px 25px;
		background-repeat: no-repeat;
		box-shadow: inset 0 -4px 0 0 hsla(0, 0%, 100%, 0.35);
		text-align: left;
	}

	.nav-link:hover {
		background-image: url('../images/nav-arrow-icon-02.svg');
		background-size: 25px;
	}

	.brand {
		width: 100%;
		max-width: 500px;
		margin-top: 0px;
		margin-bottom: 0px;
		float: left;
	}

	.header-container {
		border-bottom: 4px none #325b8f;
		box-shadow: 1px 1px 50px 0 hsla(0, 0%, 56%, 0.5);
	}

	.nav-section.inside-page {
		background-color: #fff;
		background-image: none;
	}

	.menu-button {
		padding: 13px 16px 28px;
		background-color: #052650;
		background-image: none;
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
	}

	.menu-button:hover {
		background-color: #009770;
		background-image: none;
	}

	.menu-button.w--open {
		padding-top: 15px;
		padding-bottom: 25px;
	}

	.menu-button.w--open:hover {
		background-color: #e0b300;
		background-image: none;
	}

	.dropdown {
		width: 100%;
	}

	.menu-text {
		font-family: 'Open Sans', sans-serif;
		font-size: 0.7rem;
		line-height: 0.7rem;
	}

	.dropdown-toggle {
		height: 60px;
		padding-top: 1rem;
		padding-bottom: 1.25rem;
		padding-left: 2rem;
		box-shadow: inset 0 -4px 0 0 hsla(0, 0%, 100%, 0.35);
		text-align: left;
	}

	.social-media-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
		margin-top: 0px;
		margin-right: 18px;
		margin-bottom: 0px;
		padding-bottom: 0.4rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.menu-wrapper-desktop {
		width: 90%;
		float: right;
		-webkit-transition: all 500ms ease;
		transition: all 500ms ease;
	}

	.social-media-wrapper {
		padding: 0rem 0px 0rem 15px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.form-block {
		margin-top: 0rem;
	}

	.search-form-content-wrapper {
		width: 85%;
		max-width: 1200px;
		min-width: 600px;
	}

	.icon {
		color: #fff;
		font-size: 2.15rem;
	}

	.search-heading {
		font-size: 1.35rem;
		font-weight: 700;
	}

	.listing-price {
		width: 35%;
		margin-right: 15px;
	}

	.content-box-heading {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.search-button {
		margin-bottom: 0px;
	}

	.property-spotlight-section {
		padding-top: 0rem;
	}

	.paragraph-cb-text p {
		width: 100%;
		padding-right: 0px;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.paragraph-cb-text.about-us p {
		font-size: 1.25rem;
		line-height: 2rem;
	}

	.main-section {
		padding-top: 70px;
	}

	.multi-slider-container {
		width: 100%;
	}

	.multi-slider-container.footer {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.contact-information {
		display: block;
		width: 350px;
		float: left;
	}

	.social-media-icon-wrapper {
		width: 90%;
		margin-right: 5px;
		float: none;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.social-media-icon-wrapper.footer {
		width: auto;
	}

	.footer-heading {
		width: 100%;
		margin-bottom: 0.75rem;
		margin-left: 0px;
		font-family: 'Open Sans', sans-serif;
		font-size: 1.25rem;
	}

	.footer-heading.navigation {
		width: auto;
		margin-bottom: 0.75rem;
	}

	.footer-heading.social {
		width: auto;
		margin-right: 1rem;
		margin-bottom: 0.5rem;
	}

	.contact-info-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.contact-info-wrapper.first {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.iowa-realty-footer-link {
		width: 100%;
		float: left;
		text-decoration: none;
	}

	.copyright-info {
		padding-bottom: 4.5rem;
	}

	.copyright-info.tablet {
		display: block;
	}

	.footer-list-item {
		margin-right: 0px;
	}

	.footer-list-item.one {
		margin-right: 0px;
		padding-right: 0px;
	}

	.unordered-list.two {
		width: 32%;
		float: left;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.unordered-list.one {
		width: 68%;
		float: left;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.contact-info-div {
		font-size: 1.05rem;
	}

	.logo-footer-wrapper {
		width: 100%;
	}

	.footer-content-wrapper.first {
		margin-bottom: 0px;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		padding-left: 2rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-content-wrapper.second {
		padding: 1.5rem 20px 1.5rem 2rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		min-width: 280px;
		margin-left: auto;
	}

	.footer-content-wrapper.third {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		border-left: 3px solid #162742;
		background-color: #0c366c;
	}

	.slider-form {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.search-div {
		width: 50%;
	}

	.search-div.half {
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.search-div.half.third {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.search-div.third {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.slide-nav-main {
		max-width: 500px;
	}

	.search-title-div {
		padding-left: 2rem;
	}

	.quicklinks-wrapper {
		max-width: 250px;
		margin-left: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.listing-status-text {
		font-size: 1rem;
	}

	.search-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.main-content-container.inside.full {
		margin-top: 0rem;
		padding-top: 2rem;
		padding-right: 2rem;
		padding-left: 2rem;
		background-color: #ebebeb;
	}

	.main-content-container.inside.full.vertical-search {
		padding-top: 0rem;
		padding-right: 0rem;
	}

	.main-content-container.inside.left {
		padding-top: 2rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.footer-column-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.main-content-wrapper.inside-left {
		padding-right: 10px;
		padding-left: 10px;
	}

	.main-content-wrapper.inside-left-right {
		padding-right: 10px;
		padding-left: 10px;
	}

	.footer-signoff-grip-white {
		float: none;
	}

	.signoff-column {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 50%;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.signoff-column.first {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.about-us-content-wrap {
		width: 100%;
		min-width: auto;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.about-us-div._2 {
		padding-top: 2rem;
		padding-bottom: 1.5rem;
	}

	.hero-rotator-section-kburns {
		position: relative;
		width: 100%;
	}

	.hero-rotator-widget-kburns {
		width: 100%;
		max-height: none;
		min-height: 525px;
	}

	.ken-burns-slide-2 {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 100%;
	}

	.property-spotlight-wrapper.tile {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.listing-details-heading-2 {
		font-size: 1rem;
	}

	.property-slider-arrow-link {
		bottom: 40px;
		width: 60px;
		height: 60px;
		margin-right: 20px;
		margin-left: 20px;
		border-width: 6px;
		background-color: hsla(0, 0%, 100%, 0.55);
		box-shadow: 9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 hsla(0, 0%, 100%, 0.65);
	}

	.property-slider-arrow-link:hover {
		box-shadow: 9px 9px 30px 0 rgba(90, 90, 90, 0.45);
	}

	.property-slider-arrow-link.property-arrow-right {
		box-shadow: -9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 hsla(0, 0%, 100%, 0.65);
	}

	.property-slider-arrow-link.property-arrow-right:hover {
		box-shadow: -9px 9px 30px 0 rgba(90, 90, 90, 0.45);
	}

	.property-spotlight-slide {
		width: 96%;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		background-image: none;
		font-size: 1.25rem;
	}

	.listing-details-2 {
		font-size: 1rem;
	}

	.property-spotlight-image-link {
		display: block;
	}

	.property-spotlight-image-link.tile {
		height: 100%;
	}

	.property-spotlight-image-link.tile.vertical {
		height: 250px;
	}

	.image-listing-text.tile-vertical {
		height: 250px;
	}

	.property-spotlight-mask {
		width: 100%;
		margin-right: 20px;
		margin-left: 20px;
	}

	.auction-date {
		width: 100%;
		margin-right: 15px;
	}

	.blockquote {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		font-size: 1.3rem;
		line-height: 1.9rem;
	}

	.main-content-wrap.nested-content-wrap {
		margin-right: 0em;
	}

	.inside-content-background {
		max-width: 100%;
		padding: 1.5rem 1.5rem 2rem;
	}

	.inside-content-background.full {
		padding-bottom: 2rem;
	}

	.inside-content-background.inside-left {
		padding-bottom: 2rem;
	}

	.inside-page-header-content-wrap {
		margin-bottom: 1rem;
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.inside-page-header-content-wrap.right-sidebar {
		padding-top: 0rem;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.inside-row.inside-right {
		margin-right: 0px;
		margin-left: 0px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.intro_text_style,
	.intro-text-style {
		margin-top: 0rem;
	}

	.main-content-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.container {
		max-width: 100%;
		padding-right: 20px;
		padding-left: 20px;
	}

	.main-content-wrapper.inside-right {
		padding-right: 10px;
		padding-left: 10px;
	}

	.right-sidebar-column .box,
	.sidebar-section-wrap {
		margin-left: 0em;
	}

	.right-sidebar-column .box:last-of-type,
	.sidebar-section-wrap.last {
		margin-bottom: 0rem;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
		border-top: 3px solid #00a583;
	}

	.right-sidebar-column.right-sidebar {
		padding-right: 0px;
		padding-left: 0px;
		border-top-color: #00a583;
	}

	.page-title {
		margin-top: 0rem;
		margin-bottom: 0rem;
	}

	.property-details-slider {
		width: 100%;
		aspect-ratio: 4/3;
		height: unset;
		min-height: unset;
		max-height: 70vh;
	}

	.tool.print {
		width: 44px;
		height: 44px;
		min-height: 44px;
		min-width: 44px;
		margin-top: 1rem;
		margin-right: 0px;
		margin-left: 0px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.listing-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container-2 {
		max-width: 730px;
	}

	.container-2.listing-vertical-search-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.listing-info {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-bottom: 1px solid #e4e4e4;
	}

	.listing-column {
		width: 50%;
		margin-bottom: 30px;
	}

	.listing-column.new {
		width: 100%;
		margin-bottom: 0px;
		padding-top: 0rem;
	}

	.listing-column._2nd {
		width: 100%;
		margin-bottom: 0rem;
		padding-top: 0rem;
		background-color: transparent;
		border-right: 0px solid #e4e4e4;
		border-left: 0px solid #e4e4e4;
	}

	.listings-tools {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-top: 0px;
		margin-bottom: 10px;
		float: left;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.tab-content-1 {
		display: block;
	}

	.main-content-column-nested .tab-link-new {
		width: 100%;
		border-bottom: 6px solid #288ab1;
		border-right-style: none;
	}

	.main-content-column-nested .tab-link-new:hover {
		border-right-style: none;
		border-bottom-style: solid;
	}

	.main-content-column-nested .tab-link-new.w--current {
		width: 100%;
		border-bottom: 6px solid #68b9e3;
		border-right-style: none;
	}

	.tabs-menu {
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.tabs-content {
		width: 100%;
	}

	.tabs-content-2 {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.tabs-new {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.properties-div {
		width: 100%;
		padding-top: 0rem;
		padding-bottom: 0rem;
		padding-left: 0rem;
	}

	.div-button-wrapper {
		width: 100%;
	}

	.search-container {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.inside-search-field {
		padding-right: 6px;
		padding-left: 6px;
		font-size: 0.825rem;
	}

	.select-field {
		padding-right: 6px;
		padding-left: 6px;
		font-size: 0.825rem;
	}

	.property-tile-div {
		padding-bottom: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-spotlight-tile {
		width: 100%;
		margin-right: 0%;
		margin-left: 0%;
		padding-right: 0.5rem;
		padding-bottom: 1rem;
		padding-left: 0.5rem;
		background-image: none;
		font-size: 1.25rem;
	}

	.pagination-div {
		padding-top: 0.5rem;
	}

	.pin-link._15 {
		display: none;
	}

	.pin-link._19 {
		padding-bottom: 0px;
		padding-left: 21px;
	}

	.pin-link._21 {
		display: none;
		padding-bottom: 0px;
	}

	.pin-selection-pop-up-details {
		right: 10px;
		bottom: 171px;
	}

	.pselction-pop-up-square {
		width: 100%;
		height: 100%;
	}

	.pop-up-image {
		background-size: cover;
	}

	.title-button-wrap {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.page-title-properties {
		margin-top: 0rem;
		margin-bottom: 0rem;
	}

	.vertical-search-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-bottom: 2rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none;
	}

	.property-type-div {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.property-subtype-div {
		width: 100%;
	}

	.beds-div {
		width: 100%;
	}

	.baths-div {
		width: 100%;
	}

	.pool-div {
		width: 100%;
	}

	.district-div {
		width: 100%;
	}

	.municipality-div {
		width: 100%;
	}

	.parish-div {
		width: 100%;
	}

	.status-div {
		width: 100%;
	}

	.property-id-div {
		width: 100%;
	}

	.price-range-div {
		width: 100%;
	}

	.area-range-div {
		width: 100%;
	}

	.main-content-column-nested .label-section-title {
		border-right: 1px solid #d8d8d8;
		border-left-color: #0077b7;
		background-color: #fff;
		background-image: url('../images/search-icons-minus-green-07.svg');
		background-position: 97% 50%;
		background-size: 15px;
		box-shadow: inset 0 1px 0 0 #d8d8d8, inset 0 -1px 0 0 #d8d8d8;
	}

	.main-content-column-nested .label-section-title:hover {
		background-image: url('../images/search-icons-minus.svg');
		background-size: 15px;
		color: #fff;
	}

	.property-type-list-div.property {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.single-field.property {
		width: 25%;
	}

	.single-field.name {
		width: 25%;
	}

	.single-field.half {
		width: 25%;
	}

	.single-field.third {
		width: 25%;
	}

	.property-type-field-label {
		font-size: 0.85rem;
	}

	.price-min-input {
		width: 40%;
	}

	.min-and-max-vertical-search-div {
		width: 40%;
		padding-right: 1rem;
	}

	.area-min-input {
		width: 40%;
	}

	.price-max-input {
		width: 40%;
	}

	.area-maximum-input {
		width: 40%;
	}

	.clear-all-div {
		width: 40%;
		padding: 1rem;
	}

	.clear-all-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0.25rem;
	}

	.vertical-search-form {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.vertical-search-form-div {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		box-shadow: 0 0 80px 0 rgba(0, 0, 0, 0.1);
	}

	.price-range-wrapper {
		width: 60%;
	}

	.area-range-wrapper {
		width: 60%;
	}

	.properties-details-div {
		width: 100%;
		padding-top: 0rem;
		padding-bottom: 0rem;
		padding-left: 0rem;
	}

	.property-overview-details-title {
		width: 50%;
		max-width: 250px;
		margin-right: 1rem;
	}

	.search-filter-heading {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 60%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.search-heading-div {
		width: 1000%;
		padding-top: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.search-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 0.25rem;
	}

	.property-type-list-div-2.property-subtype {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		border-top: 1px none #d8d8d8;
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-3.beds {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-right-width: 1px;
		border-right-color: #d8d8d8;
		border-bottom-width: 1px;
		border-bottom-color: #d8d8d8;
		border-left-width: 1px;
		border-left-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-4.baths {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-5.pool {
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-6.district {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-7.town {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-8.parish {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-9.status {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-10.property-id {
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-11.price-range {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-12.area {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-style: none solid solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.listing-title {
		border-right-style: none;
	}

	.listing-title.bottom {
		border-bottom-style: solid;
	}

	.property-overview-details {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-container {
		width: 100%;
	}

	.footer-container.footer {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.phone-link {
		display: inline-block;
	}

	.phone-no-link {
		display: none;
	}
}

@media (max-width: 767px) {
	.page-wrapper {
		font-family: 'Open Sans', sans-serif;
		background-color: hsla(55, 28%, 92%, 0.7);
	}

	h1 {
		font-size: 2.15rem;
		line-height: 2.175rem;
	}

	h2 {
		font-size: 1.675rem;
		line-height: 1.925rem;
	}

	h3 {
		font-size: 1.25rem;
		line-height: 1.6rem;
	}

	.left-sidebar-custom h1,
	.left-sidebar-custom h2,
	.left-sidebar-custom h3,
	.left-sidebar-custom h4,
	.right-sidebar-column h1,
	.right-sidebar-column h2,
	.right-sidebar-column h3,
	.right-sidebar-column h4,
	h4 {
		font-size: 1.15rem;
		line-height: 1.4rem;
	}

	h5 {
		font-size: 0.95rem;
		line-height: 1.35rem;
	}

	h6 {
		font-size: 0.875rem;
		text-transform: none;
	}

	.button.secondary.tender {
		margin-right: 0.5rem;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.main-content-column-nested .button.inquiry {
		width: 100%;
		margin-right: 0.5rem;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.button.search-properties {
		width: 25%;
		height: 50px;
		max-width: none;
		min-width: 50%;
		margin-top: 0.5rem;
		margin-right: 0rem;
		padding: 10px 0.25rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		border-width: 2px;
		border-color: #0c366c;
		font-size: 0.97rem;
	}

	.button.search-properties:hover {
		border-color: #1c5ba2;
	}

	.branding-content-wrapper {
		width: 90%;
	}

	.logo-wrapper {
		display: block;
		width: 100%;
		margin-bottom: 0px;
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		float: none;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.nav-menu-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.logo-image {
		width: 100%;
	}

	.brand {
		display: block;
		width: 100%;
		margin-top: 0px;
		margin-bottom: 0px;
		padding-top: 0px;
		float: none;
	}

	.header-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.nav-section {
		padding-bottom: 0px;
		float: right;
		border-bottom: 3px solid #e2e2e2;
	}

	.nav-section.inside-page {
		float: none;
		border-bottom-style: none;
	}

	.menu-button {
		font-size: 1.35rem;
		margin-left: auto;
	}

	.menu-button.w--open {
		background-color: #052650;
		cursor: pointer;
	}

	.header-contents-wrap.inside {
		margin-bottom: 0px;
	}

	.dropdown-nav-link {
		width: 80%;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		text-align: left;
		text-decoration: none;
	}

	.dropdown {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.dropdown-toggle {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.social-media-link {
		display: block;
		margin-right: auto;
		margin-left: auto;
		padding: 0.65rem 0.75rem 0.65rem 2rem;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.social-media-link._2 {
		display: block;
		margin-right: 1rem;
		margin-left: 0px;
		padding-top: 0rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.social-media-link._3 {
		height: 40px;
		margin-left: 0px;
		padding-top: 0rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.social-media-link._1 {
		position: static;
		margin-right: 1rem;
		margin-left: 1.75rem;
		padding-top: 0rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.social-media-link._1.footer {
		width: 40px;
	}

	.social-media-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		padding: 0px;
		float: none;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		background-color: #1a457c;
	}

	.facebook {
		display: block;
	}

	.facebook:hover {
		border-color: #233d7a;
		cursor: pointer;
	}

	.form-text-field {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-top: 6px;
		padding-bottom: 6px;
	}

	.search-form-content-wrapper {
		width: auto;
		height: 100%;
		max-width: none;
		min-width: 0px;
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.icon {
		font-size: 2.05rem;
	}

	.search-form {
		display: block;
		height: auto;
	}

	.form-select-field {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-top: 6px;
		padding-bottom: 6px;
	}

	.listing-price {
		width: 100%;
	}

	.search-button {
		float: right;
		box-shadow: none;
	}

	.property-spotlight-section {
		padding-top: 0px;
	}

	.property-spotlight-section._2 {
		background-image: -webkit-linear-gradient(270deg, hsla(0, 0%, 100%, 0.3), hsla(0, 0%, 100%, 0.3));
		background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.3), hsla(0, 0%, 100%, 0.3));
	}

	.paragraph-cb-text p {
		width: 100%;
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.paragraph-cb-text.about-us p {
		padding-right: 0rem;
		font-size: 1.05rem;
		line-height: 1.75rem;
	}

	.main-section {
		display: inline-block;
		width: 100%;
		padding-top: 140px;
	}

	.multi-slider-container {
		width: 100%;
	}

	.copyright-text {
		width: 100%;
		text-align: center;
	}

	.contact-information {
		padding-left: 0px;
		float: none;
		text-align: center;
	}

	.social-media-icon-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		padding-top: 5px;
		padding-bottom: 5px;
		float: none;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.social-media-icon-wrapper.footer {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.footer-heading {
		width: 100%;
		float: none;
		text-decoration: none;
	}

	.footer-heading.navigation {
		float: none;
		text-align: left;
	}

	.footer-heading.social {
		width: 100%;
		float: none;
		text-align: center;
	}

	.contact-info-wrapper.first {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.copyright-info {
		padding-bottom: 5rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.copyright-info.tablet {
		width: 100%;
		margin-top: 10px;
	}

	.footer-list-item {
		text-align: left;
	}

	.unordered-list {
		width: 35%;
	}

	.unordered-list.one {
		margin-bottom: 0px;
		float: left;
	}

	.contact-info-div {
		width: 100%;
		float: none;
		text-align: center;
	}

	.logo-footer-wrapper {
		width: 100%;
		padding-top: 5px;
		padding-bottom: 5px;
		float: none;
	}

	.footer-column-wrapper {
		flex-direction: column;
	}

	.footer-content-wrapper.first {
		padding-left: 1.5rem;
	}

	.footer-content-wrapper.second {
		padding-right: 1.5rem;
		margin-left: 0;
		padding-left: 1.5rem;
	}

	.footer-content-wrapper.third {
		display: block;
		width: 100%;
		padding-top: 1.25rem;
		padding-bottom: 1.5rem;
		float: none;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-left-style: none;
		background-image: none;
	}

	.search-div.half.third {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.search-div.scale.half {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.search-div.scale.half.inside {
		margin-bottom: 1.25rem;
	}

	.search-div.third {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.slide-nav-main {
		bottom: 60px;
		display: none;
		background-image: none;
	}

	.search-title-div {
		padding-left: 1.5rem;
		background-color: hsla(0, 0%, 100%, 0.25);
	}

	.quicklinks-wrapper {
		max-width: none;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.status-bar-div {
		height: 33px;
	}

	.search-wrapper {
		position: relative;
		left: 0px;
		top: 240px;
		right: 0px;
		bottom: 243px;
		display: block;
		width: auto;
		max-width: 700px;
		margin-right: 2rem;
		margin-left: 2rem;
	}

	.main-content-container.inside.full {
		padding: 0rem;
	}

	.main-content-container.inside.left {
		padding: 0rem;
	}

	.footer-column-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.main-content-wrapper.inside-left {
		padding-right: 0px;
		padding-left: 0px;
	}

	.main-content-wrapper.inside-left-right {
		padding-right: 0px;
		padding-left: 0px;
	}

	.footer-signoff-grip-white {
		margin-top: 0.5rem;
	}

	.signoff-column.first {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.signoff-column.second {
		width: 100%;
		padding-top: 0.25rem;
		padding-bottom: 0.75rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.about-us-content-wrap {
		display: none;
		padding-top: 2rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.linkedin {
		display: block;
		margin-top: 0px;
	}

	.linkedin:hover {
		border-color: #233d7a;
	}

	.instagram {
		display: block;
		margin-top: 0px;
	}

	.instagram:hover {
		border-color: #233d7a;
	}

	.about-us-div._2 {
		padding-top: 441px;
	}

	.search-div-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.hero-rotator-widget-kburns {
		height: 300px;
		max-height: none;
		min-height: 0px;
	}

	.ken-burns-wrapper._1 {
		background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.33), transparent 30%), url('../images/home_01_1903x691.jpg');
		background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.33), transparent 30%), url('../images/home_01_1903x691.jpg');
		background-position: 0px 0px, 50% 50%;
		background-size: auto, cover;
	}

	.left-arrow-two {
		display: none;
	}

	.right-arrow-two {
		display: none;
	}

	.arrow-icon {
		top: 100px;
	}

	.property-spotlight-wrapper {
		width: 100%;
		padding-right: 0px;
	}

	.property-spotlight-wrapper.tile {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.listing-details-column-2 {
		width: 100%;
	}

	.property-slider-arrow-link {
		bottom: 255px;
		width: 65px;
		height: 65px;
		margin-right: 20px;
		margin-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-width: 5px;
		background-color: rgba(96, 96, 96, 0.5);
		box-shadow: 9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 rgba(96, 96, 96, 0.3);
	}

	.property-slider-arrow-link.property-arrow-right {
		box-shadow: -9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 rgba(96, 96, 96, 0.3);
	}

	.property-spotlight-slide {
		width: 100%;
		margin-right: 0%;
		margin-left: 0%;
		padding-left: 0rem;
	}

	.property-slider-widget {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.property-spotlight-image-link.tile {
		height: 250px;
	}

	.property-spotlight-mask {
		margin-right: 2rem;
		margin-left: 2rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.listing-status-2.for-sale-turpuoise {
		margin-top: -1rem;
	}

	.listing-status-2.sold-orange {
		margin-top: -1rem;
	}

	.listing-status-2.for-rent-green {
		margin-top: -1rem;
	}

	.property-slider-outer-wrap {
		padding-top: 1.5rem;
	}

	.auction-date {
		width: 100%;
	}

	.blockquote {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		font-size: 1.25rem;
	}

	.inside-content-background {
		padding: 1.25rem 1.5rem 1.5rem;
	}

	.inside-content-background.full {
		padding-bottom: 1.5rem;
	}

	.inside-content-background.inside-left {
		padding-bottom: 1.5rem;
	}

	.inside-page-header-content-wrap {
		padding-right: 0px;
		padding-left: 0px;
	}

	.intro_text_style,
	.intro-text-style {
		font-size: 1.125em;
	}

	.container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.main-content-wrapper.inside-right {
		padding-right: 0px;
		padding-left: 0px;
	}

	.right-sidebar-column .box,
	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.right-sidebar-column {
		margin-top: 1.25rem;
		padding-top: 1.25rem;
	}

	.property-description {
		-webkit-column-count: 1;
		column-count: 1;
		-webkit-column-gap: 0em;
		column-gap: 0em;
	}

	.listing-map-widget {
		height: 300px;
	}

	.property-details-slider {
		width: 100%;
		height: calc(100vw * 0.75);
		min-height: unset;
		max-height: 60vh;
	}

	.tool.print {
		width: 44px;
		height: 44px;
		min-height: 44px;
		min-width: 44px;
		margin-top: 1rem;
	}

	.listing-row {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.container-2 {
		max-width: 728px;
	}

	.listing-info {
		width: 100%;
		padding-left: 0px;
	}

	.listing-column {
		width: 100%;
		margin-bottom: 0px;
	}

	.listing-column.new {
		width: 100%;
		margin-bottom: 0px;
	}

	.listing-column._2nd {
		width: 100%;
		margin-bottom: 0px;
	}

	.listings-tools {
		padding-bottom: 25px;
	}

	.main-content-column-nested .tab-link-new {
		max-width: none;
		border-bottom-width: 6px;
	}

	.main-content-column-nested .tab-link-new.w--current {
		border-bottom-width: 6px;
	}

	.tabs-menu {
		height: 52px;
	}

	.search-container {
		margin-top: -5px;
		padding-top: 1.75rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.search-container.property-search {
		margin-top: 0px;
		padding-bottom: 1.75rem;
		background-color: hsla(0, 0%, 100%, 0.25);
	}

	.inside-search-form {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.inside-search-field {
		width: 50%;
	}

	.select-field {
		width: 50%;
	}

	.property-spotlight-tile {
		width: 100%;
	}

	.pagination-page-link {
		height: 30px;
	}

	.pin-selection-pop-up-details {
		position: static;
		top: auto;
		right: 0px;
		bottom: auto;
		width: 100%;
		height: 100px;
		max-width: 100%;
		min-width: 424px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		opacity: 1;
	}

	.pselction-pop-up-square {
		min-width: 100%;
		padding-right: 1.25rem;
		border-style: none;
		box-shadow: inset 6px 0 0 0 #288ab1;
	}

	.pop-up-image {
		width: 100%;
		height: 125px;
		background-image: url('../images/home_08_1903x691.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		opacity: 1;
	}

	.single-field.property {
		width: 50%;
	}

	.single-field.name {
		width: 50%;
	}

	.single-field.half {
		width: 50%;
	}

	.single-field.third {
		width: 33.33%;
	}

	.property-type-field-label {
		overflow: visible;
	}

	.price-min-input {
		width: 45%;
	}

	.min-and-max-vertical-search-div {
		width: 85%;
	}

	.area-min-input {
		width: 45%;
	}

	.price-max-input {
		width: 45%;
	}

	.area-maximum-input {
		width: 45%;
	}

	.clear-all-div {
		width: 100%;
	}

	.price-range-wrapper {
		width: 100%;
	}

	.area-range-wrapper {
		width: 100%;
	}

	.property-overview-details-title {
		margin-right: 0.5rem;
	}

	.search-filter-heading {
		width: 100%;
		border-bottom: 1px solid #d8d8d8;
	}

	.property-type-list-div-3.beds {
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.footer-container {
		width: 100%;
	}
}

@media (max-width: 650px) {
	.footer-column-wrapper {
		flex-direction: column;
	}

	.footer-content-wrapper.second {
		margin-left: 0;
		padding-left: 1.5rem;
	}
}

@media (max-width: 479px) {
	h1 {
		font-size: 2.05rem;
	}

	h2 {
		font-size: 1.6rem;
		line-height: 1.9rem;
	}

	h3 {
		font-size: 1.2rem;
		line-height: 1.55rem;
	}

	.left-sidebar-custom h1,
	.left-sidebar-custom h2,
	.left-sidebar-custom h3,
	.left-sidebar-custom h4,
	.right-sidebar-column h1,
	.right-sidebar-column h2,
	.right-sidebar-column h3,
	.right-sidebar-column h4,
	h4 {
		font-size: 1.1rem;
		line-height: 1.35rem;
	}

	h6 {
		font-size: 0.825rem;
		line-height: 1.15rem;
	}

	.button.secondary.tender {
		width: 100%;
		max-width: 100%;
		margin-right: 0rem;
		margin-bottom: 0rem;
	}

	.main-content-column-nested .button.inquiry {
		width: 100%;
		max-width: 100%;
		margin-top: 0.5rem;
		margin-right: 0rem;
		margin-bottom: 0rem;
	}

	.button.search-properties {
		width: 100%;
		min-width: 100%;
		margin: 0rem;
		padding-right: 1rem;
		padding-left: 1rem;
		font-size: 1rem;
	}

	.logo-wrapper {
		display: block;
		width: 100%;
		margin-right: auto;
		margin-bottom: 0px;
		margin-left: auto;
		padding-right: 1rem;
		padding-bottom: 0.8rem;
		padding-top: 0.9rem;
		padding-left: 1rem;
		float: left;
		box-shadow: inset 10px 0 0 0 #999;
	}

	.logo-image {
		display: block;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.nav-link:hover {
		background-image: url('../images/nav-arrow-icon-02.svg');
		background-position: 97% 45%;
		background-size: 25px 25px;
	}

	.brand {
		display: block;
		width: 100%;
		margin: 0px auto;
		padding-top: 0px;
		float: none;
	}

	.nav-section {
		padding-bottom: 0px;
		float: left;
	}

	.menu-button {
		margin-top: 0px;
		margin-right: 0px;
		margin-left: auto;
		padding-right: 12px;
		padding-left: 12px;
	}

	.social-media-link {
		display: block;
		margin-right: auto;
		margin-left: auto;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		padding-left: 0px;
		text-align: center;
	}

	.social-media-link._1 {
		margin-left: 1rem;
	}

	.social-media-link._1.footer {
		margin-left: 0rem;
	}

	.menu-wrapper-desktop {
		width: 95%;
	}

	.social-media-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-top: 0px;
		margin-right: auto;
		margin-left: auto;
		padding: 0px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.facebook {
		display: block;
		width: 40px;
		margin-top: 0px;
	}

	.facebook:hover {
		border-color: #233d7a;
	}

	.form-block {
		padding-top: 0.5rem;
		padding-bottom: 0rem;
	}

	.form-text-field {
		padding-top: 6px;
		padding-bottom: 6px;
	}

	.search-form-content-wrapper {
		top: 13%;
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.icon {
		font-size: 1.5rem;
	}

	.form-select-field {
		background-image: none;
	}

	.search-heading {
		font-size: 1rem;
	}

	.listing-price {
		width: 100%;
		margin-bottom: 0.5rem;
		font-size: 1.4rem;
	}

	.content-box-heading {
		margin-bottom: 0.5rem;
		font-size: 1.5rem;
	}

	.search-button {
		height: 32px;
		min-width: 90px;
		padding-right: 8px;
		padding-left: 8px;
	}

	.property-spotlight-section {
		padding-top: 0px;
	}

	.property-spotlight-section._2 {
		padding-top: 0px;
	}

	.paragraph-cb-text p {
		font-size: 0.85rem;
		line-height: 1.35rem;
	}

	.paragraph-cb-text.about-us p {
		padding-right: 0rem;
		font-size: 0.975rem;
		line-height: 1.6rem;
	}

	.main-section {
		width: 100%;
		padding-top: 123px;
	}

	.multi-slider-container {
		width: 100%;
		padding-top: 0px;
		padding-bottom: 0px;
		line-height: 1.55rem;
	}

	.copyright-text {
		display: block;
		width: 100%;
		margin-right: auto;
		margin-bottom: 10px;
		margin-left: auto;
		float: none;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 0.825rem;
		text-align: center;
	}

	.contact-information {
		padding-left: 0px;
	}

	.social-media-icon-wrapper {
		margin-right: 0px;
	}

	.social-media-icon-wrapper.footer {
		width: 100%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.footer-heading {
		width: 100%;
		font-size: 1.15rem;
		line-height: 1.45rem;
		text-decoration: none;
	}

	.footer-heading.navigation {
		font-size: 1.15rem;
	}

	.footer-heading.social {
		margin-bottom: 0.75rem;
		font-size: 1.15rem;
		text-align: left;
	}

	.privacy-link {
		font-size: 0.85rem;
	}

	.contact-info-wrapper {
		width: 100%;
	}

	.iowa-realty-footer-link {
		width: 100%;
	}

	.copyright-info {
		padding-bottom: 0.75rem;
	}

	.copyright-info.tablet {
		width: 80%;
	}

	.footer-list-item {
		display: inline-block;
		width: 100%;
		margin-right: 10px;
		text-align: left;
	}

	.footer-list-item.one {
		width: 100%;
		text-align: left;
	}

	.footer-link {
		text-align: left;
	}

	.unordered-list.two {
		display: block;
		width: 90px;
	}

	.unordered-list.one {
		width: 30%;
		margin-bottom: 0px;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		text-align: left;
	}

	.contact-info-div {
		text-align: left;
	}

	.contact-info-div.directions {
		font-size: 0.95rem;
	}

	.logo-footer-wrapper {
		width: 100%;
	}

	.footer-content-wrapper.first {
		display: block;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		padding-right: 1.25rem;
		padding-bottom: 1rem;
		padding-left: 1.25rem;
		float: none;
	}

	.footer-content-wrapper.second {
		display: block;
		width: 100%;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		float: none;
	}

	.footer-content-wrapper.third {
		display: block;
		margin-right: auto;
		margin-left: auto;
		padding: 1.5rem 1.25rem 1.75rem;
		float: none;
	}

	.search-div {
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.search-div.half {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.search-div.half.third {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.search-div.scale {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.search-div.search {
		margin-top: 0.75rem;
	}

	.slide-nav-main {
		bottom: 52px;
		height: 32px;
	}

	.search-title-div {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		padding-left: 1rem;
	}

	.quicklinks-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.min-div._2 {
		width: 22px;
		height: 22px;
	}

	.max-div._2 {
		width: 22px;
		height: 22px;
	}

	.line-div {
		height: 8px;
	}

	.div-line-sections {
		height: 8px;
	}

	.div-line-sections.first.colored {
		height: 8px;
	}

	.div-line-sections.colored {
		height: 8px;
	}

	.listing-detail-column-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.search-wrapper {
		top: 123px;
		display: block;
		margin-right: 1.25rem;
		margin-left: 1.25rem;
	}

	.footer-signoff-bar {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.privacy {
		font-size: 0.825rem;
	}

	.signoff-column.second {
		padding-bottom: 3.75rem;
	}

	.legal {
		font-size: 0.825rem;
	}

	.sitemap-l {
		font-size: 0.825rem;
	}

	.about-us-content-wrap {
		padding-top: 2.25rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.linkedin {
		display: block;
		width: 40px;
		margin-top: 0px;
		margin-right: auto;
		margin-left: auto;
	}

	.linkedin:hover {
		border-color: #233d7a;
	}

	.instagram {
		display: block;
		width: 40px;
		margin-top: 0px;
		margin-right: auto;
		margin-left: auto;
	}

	.instagram:hover {
		border-color: #233d7a;
	}

	.about-us-div._2 {
		padding: 510px 1.25rem 0rem;
	}

	.hero-rotator-widget-kburns {
		height: 175px;
	}

	.property-spotlight-wrapper {
		padding-right: 0px;
		background-color: transparent;
	}

	.prop-spotlight-details-wrapper {
		margin-top: -12px;
	}

	.property-details-slider {
		width: 100%;
		height: calc(100vw * 0.75);
		min-height: unset;
		max-height: 400px;
	}

	.property-slider-arrow-link {
		bottom: 270px;
		width: 45px;
		height: 45px;
		margin-right: 10px;
		margin-left: 10px;
		border-width: 4px;
		font-size: 1.25rem;
	}

	.property-spotlight-slide {
		width: 100%;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.property-slider-widget {
		width: 100%;
		height: 100%;
	}

	.property-spotlight-image-link {
		height: 225px;
		margin-bottom: 0.75rem;
	}

	.property-spotlight-image-wrapper {
		height: 100%;
	}

	.image-listing-text {
		height: 225px;
	}

	.property-spotlight-image-link._2.tile {
		height: 225px;
	}

	.property-spotlight-mask {
		margin-right: 1.25rem;
		margin-left: 1.25rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.property-slider-outer-wrap {
		padding-top: 5rem;
	}

	.auction-date {
		width: 100%;
		margin-bottom: 0.5rem;
		font-size: 1.25rem;
	}

	.blockquote {
		padding-top: 1rem;
		padding-bottom: 1rem;
		font-size: 1.1rem;
		line-height: 1.75rem;
	}

	.inside-content-background {
		padding-right: 1.25rem;
		padding-bottom: 1.245rem;
		padding-left: 1.25rem;
	}

	.inside-content-background.full {
		padding-bottom: 1.25rem;
	}

	.inside-content-background.inside-left {
		padding-bottom: 1.25rem;
	}

	.inside-page-header-content-wrap {
		margin-bottom: 1rem;
	}

	.container {
		padding-right: 0px;
		padding-left: 0px;
	}

	.right-sidebar-column {
		margin-top: 1rem;
		padding-top: 1rem;
	}

	.page-title {
		font-size: 1.95rem;
	}

	.property-mortgate {
		font-size: 1.2em;
	}

	.property-description {
		padding-top: 10px;
	}

	.tool.print {
		width: 100%;
		height: 42px;
		min-height: 42px;
		margin-top: 1rem;
	}

	.property-price {
		font-size: 2em;
	}

	.container-2 {
		max-width: none;
	}

	.listing-info {
		width: 100%;
	}

	.main-content-column-nested .tab-link-new {
		height: 45px;
		min-width: 100%;
		border-bottom-color: #0057a5;
	}

	.main-content-column-nested .tab-link-new:hover {
		border-bottom-color: #00a8e4;
	}

	.main-content-column-nested .tab-link-new.w--current {
		min-width: 100%;
	}

	.tabs-menu {
		height: 90px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.div-button-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.search-container {
		margin-top: 0px;
		padding-top: 1.75rem;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.search-container.property-search {
		display: block;
		background-color: hsla(0, 0%, 100%, 0.35);
	}

	.inside-search-field {
		width: 100%;
	}

	.select-field {
		width: 100%;
	}

	.property-spotlight-tile {
		width: 100%;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.search-button-wtap {
		width: 100%;
	}

	.pin-link._1 {
		display: none;
	}

	.pin-link._4 {
		display: none;
	}

	.pin-link._10 {
		display: none;
	}

	.pin-link._14 {
		display: none;
	}

	.pin-link._18 {
		display: none;
	}

	.pin-link._19 {
		display: none;
	}

	.pin-selection-pop-up-details {
		position: static;
		width: 100%;
		min-width: 280px;
	}

	.title-button-wrap {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.button-map-list-wrap {
		margin-right: 0rem;
		margin-left: 0.5rem;
	}

	.map-view-icon-link {
		width: 37px;
		padding: 0.15rem;
	}

	.list-view-icon-link {
		width: 37px;
		padding: 0.15rem;
	}

	.page-title-properties {
		font-size: 1.95rem;
	}

	.vertical-search-wrapper {
		margin-right: 0rem;
	}

	.single-field.name {
		width: 100%;
	}

	.single-field.half {
		width: 100%;
	}

	.single-field.third {
		width: 50%;
	}

	.min-and-max-vertical-search-div {
		width: 100%;
		padding-right: 0rem;
	}

	.vertical-search-form {
		width: 100%;
	}

	.vertical-search-form-div {
		width: 100%;
	}

	.property-overview-details-title {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
	}

	.property-type-list-div-2.property-subtype {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-11.price-range {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-12.area {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.listing-title {
		font-size: 0.85rem;
	}

	.property-overview-details {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.bold-title {
		margin-right: 0.25rem;
	}

	.footer-container {
		width: 100%;
		padding-top: 0px;
		padding-bottom: 0px;
		line-height: 1.55rem;
	}
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}

sup {
	font-size: xx-small;
}

@media screen and (min-width: 992px) and (max-width: 1648px) {
	.listing-two-column-wrapper {
		width: 100%;
	}
}

@media screen and (min-width: 992px) {
	.property-spotlight-mask {
		width: calc(100% - 160px);
	}
}

@media screen and (max-width: 991px) {
	.property-spotlight-mask {
		width: calc(100% - 40px);
	}
}

@media screen and (min-width: 1024px) and (max-width: 1360px) {
	.property-spotlight-slide {
		width: 95%;
	}
}

@media screen and (min-width: 992px) and (max-width: 1023px) {
	.property-spotlight-slide {
		width: 96%;
	}
}

@media screen and (min-width: 992px) {
	.property-quad-mask {
		width: calc(100% - 140px);
	}
}

@media screen and (max-width: 991px) {
	.property-quad-mask {
		width: calc(100% - 40px);
	}
}

@media screen and (min-width: 992px) {
	.property-duo-mask {
		width: calc(100% - 140px);
	}
}

@media screen and (max-width: 991px) {
	.property-duo-mask {
		width: calc(100% - 40px);
	}
}

.w-slider-nav.w-round > div {
	background-color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
}

.w-slider-dot.w-active {
	background-color: #ffffff !important;
	color: #fff !important;
	cursor: pointer;
}

.footer-section a[href^=tel] {
	-webkit-transition: all 250ms ease;
	text-decoration: none;
	color: inherit;
}

::-moz-selection {
	background-color: #e3e3e3;
	color: #000;
	opacity: 1;
}

::selection {
	background-color: #e3e3e3;
	color: #000;
	opacity: 1;
}

/*slideshow animation*/
.w-slide {
	transition: opacity 2000ms;
	visibility: visible !important;
}

.w-slide.cycle-slide-active {
	opacity: 1 !important;
}

.ken-burns-wrapper {
	transition: transform 20000ms;
	transform: translateX(0px) translateY(0px);
}

.w-slide.cycle-slide-active .ken-burns-wrapper {
	transition: transform 15000ms;
	transform: translateX(100px) translateY(0px);
}

/*accessible main menu*/
.nav-menu-wrap {
	margin-bottom: 0;
	max-width: 1000px;
}

.dropdown {
	margin-bottom: 0;
	text-align: center;
}

.dropdown-nav-link:hover {
	background-color: #0c366c;
	box-shadow: inset 0 -5px 0 0 #3f97de;
	color: #fff;
}

.has-dropdown:hover .dropdown-nav-link {
	background-color: #0c366c;
	box-shadow: inset 0 -5px 0 0 #68b9e3;
	color: #fff;
}

.dropdown-list {
	height: 0;
	opacity: 0;
	display: block;
	list-style-type: none;
	margin: 0;
	padding-left: 0;
	text-align: left;
}

.has-dropdown.open .dropdown-list {
	transition: opacity 500ms;
	opacity: 1;
	height: auto;
}

.dropdown-list li {
	display: block;
	margin-bottom: 0;
}

.dropdown-list a {
	margin-bottom: 0rem;
	padding: 1rem;
	border-bottom-style: none;
	background-color: transparent;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #0c366c;
	font-size: 1.05rem;
	font-weight: 600;
	display: block;
}

.dropdown-list a:hover {
	border-bottom-style: none;
	background-color: #fff;
	color: #1c5ba2;
}

.dropdown-list a.w--current {
	border-bottom-style: none;
	background-color: #fff;
	color: #1c5ba2;
}

/*homepage search form*/
.two-point-slider {
	margin: 11px auto 8px;
	left: -1%;
	max-width: 96%;
	box-shadow: none;
	background-color: #dfdfdf;
	height: 10px;
}

.noUi-horizontal .noUi-handle {
	background-color: #0057a5;
	width: 25px;
	height: 25px;
	border-radius: 2.25rem;
	border: 1px solid #0057a5;
	box-shadow: none;
	cursor: pointer;
	top: -9px;
}

.noUi-handle:after,
.noUi-handle:before {
	height: 11px;
	width: 2px;
	background: hsla(0, 0%, 100%, 0.7);
	left: 7px;
	top: 6px;
}

.noUi-handle:after {
	left: 14px;
}

.noUi-marker-large,
.noUi-value-large,
.noUi-value-sub {
	display: none;
}

.noUi-marker-sub {
	background: #a5a5a5;
}

.noUi-pips-horizontal {
	padding: 0;
	height: 9px;
	margin-top: -9px;
}

.noUi-connect {
	background: rgba(43, 167, 212, 0.5) !important;
}

@media (max-width: 479px) {
	.two-point-slider {
		max-width: 93%;
	}
}

.owl-nav {
	display: none;
}

/*mmenu*/
.mobile-navigation-menu {
	display: none !important;
}

@media (min-width: 992px) {
	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}

	.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(-80%, 0);
		-ms-transform: translate(-80%, 0);
		transform: translate(-80%, 0);
		-webkit-transform: translate3d(-80%, 0, 0);
		transform: translate3d(-80%, 0, 0);
	}

	.mm-menu_offcanvas {
		max-width: none;
		background-color: #1c5ba2;
	}

	.mm-panel {
		padding: 0;
	}

	.mm-panels > .mm-panel > .mm-listview {
		margin: -20px 0 20px;
	}

	.mm-panels > .mm-panel + .mm-panel > .mm-listview {
		margin-top: 39px;
	}

	.mm-menu li {
		display: block !important;
		border-bottom: 4px solid hsla(0, 0%, 100%, 0.35);
	}

	.mm-menu a,
	.mm-menu a:active,
	.mm-menu a:link,
	.mm-menu a:visited {
		color: #fff;
		padding: 1.25rem 1rem 1rem 2rem;
		box-shadow: none;
		background-image: none;
	}

	.mm-menu a:hover {
		cursor: pointer;
	}

	.mm-listitem:after {
		border-bottom-width: 0px;
	}

	.mm-menu .mm-btn_prev:hover,
	.mm-menu .mm-navbar__title:hover,
	.mm-menu .mm-btn_next:hover {
		color: #fff;
		background-color: transparent;
		cursor: pointer;
	}

	.mm-menu .mm-listitem .mm-btn_next {
		background-image: url("../images/nav-arrow-icon-01.svg");
		background-position: 46% 49%;
		background-size: 25px 25px;
		background-repeat: no-repeat;
	}

	.mm-menu .mm-listitem .mm-btn_next:hover {
		background-image: url("../images/nav-arrow-icon-02.svg");
	}

	.mm-listitem .mm-btn_next:before {
		border-left-width: 0;
	}

	.mm-listitem .mm-btn_next:after {
		display: none;
	}

	.mm-menu .mm-btn:after,
	.mm-menu .mm-btn:before,
	.mm-menu .mm-listview .mm-btn_next:after {
		border-color: #1c5ba2;
	}

	.mm-menu a.mm-btn_prev,
	.mm-menu a.mm-btn_prev:active,
	.mm-menu a.mm-btn_prev:link,
	.mm-menu a.mm-btn_prev:visited {
		background-image: url("../images/nav-arrow-icon-01.svg");
		background-position: 13% 49%;
		background-size: 25px 25px;
		background-repeat: no-repeat;
		width: 60px;
		-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		transform: rotate(180deg);
	}

	.mm-btn_prev:before {
		display: none;
	}

	.mm-menu .mm-listview .mm-btn_next + a {
		width: calc(100% - 53px);
	}

	.mm-navbar {
		border-bottom: 4px solid hsla(0, 0%, 100%, 0.35);
		padding: 0 10px 0 40px;
		height: 60px;
	}

	.mm-menu .mm-navbar a,
	.mm-menu .mm-navbar > * {
		height: 60px;
	}

	.mm-menu a.mm-navbar__title {
		font-size: 0.98rem;
		line-height: 1.25rem;
		font-weight: 600;
		text-transform: uppercase;
		text-align: left;
		padding-left: 35px;
	}

	.mm-navbar:hover {
		background-color: #0c366c;
		cursor: pointer;
	}

	.mm-navbar:hover .mm-navbar__title {
		color: #fff;
		cursor: pointer;
	}

	.mm-navbar:hover a.mm-btn_prev {
		background-image: url("../images/nav-arrow-icon-02.svg");
	}
}

/*inside pages*/

/*strong*/
b,
strong {
	font-weight: 600;
}

/*left sidebar*/
.left-sidebar-custom {
	padding-right: 20px;
	padding-left: 10px;
	margin-top: 20px;
}

.left-sidebar-custom .box {
	margin: 0 0 20px;
}

.left-sidebar-custom .image-ad {
	margin: 0;
}

/*right column*/
.right-sidebar-column .box {
	margin-bottom: 2rem;
}

.right-sidebar-column .box.documents .content > table {
	margin-bottom: 15px;
}

.right-sidebar-column .box.documents .content td:first-of-type {
	padding-right: 10px;
	width: 35px;
}

.right-sidebar-column .box .contentbox_item {
	margin-bottom: 15px;
}

/*messages*/
.success {
	display: block;
	text-align: left;
	margin-top: 1rem;
	margin-bottom: 1rem;
	color: #419f37;
	font-size: 1rem;
	line-height: 1.5rem;
	padding: 0.75rem 1rem 1rem 1.25rem;
	border-style: solid;
	border-width: 10px 1px 1px;
	border-color: #419f37;
	border-radius: 3px;
	background-color: #fff;
}

.error {
	display: block;
	text-align: left;
	margin-top: 1rem;
	margin-bottom: 1rem;
	color: #ed1b24;
	font-size: 1rem;
	line-height: 1.5rem;
	padding: 0.75rem 1rem 1rem 1.25rem;
	border-style: solid;
	border-width: 10px 1px 1px;
	border-color: #ed1b24;
	border-radius: 3px;
	background-color: #fff;
}

.success p,
.success ul li,
.error p,
.error ul li {
	margin-bottom: 0;
	margin-top: 0;
	color: #419f37;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.error p,
.error ul li {
	color: #ed1b24;
	font-size: 1rem;
}

/*tables*/
table.styled {
	width: 100%;
	margin: 20px 0 10px 0;
	background-color: transparent;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #0c366c;
	color: white;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #1c5ba2;
	color: white;
}

table.styled th a {
	color: inherit;
	text-decoration: underline;
	border-bottom: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled tr:nth-child(even) {
	background: #cce0f0;
}

table.styled tr:nth-child(odd) {
	background: #fafafa;
}

/*tablesaw*/
@media (max-width: 39.9375em) {
	table.tablesaw.styled th,
	table.tablesaw.styled td {
		border-right: 0 none;
	}
}

/*images*/
img {
	height: auto;
}

td img {
	max-width: none;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/*forms*/
.cms_label,
.label,
label {
	font-size: 1rem;
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
textarea,
select {
	font-size: 0.875rem;
	line-height: 1.42857143;
	padding: 8px 12px;
	border: 1px solid #cccccc;
	color: #333333;
	border-radius: 0;
}

textarea {
	resize: vertical;
}

/*blockquotes*/
blockquote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1.75rem 1.5rem;
	border-left: 5px solid #009770;
	background-color: #f2f2f2;
	color: #636363;
	font-size: 1.35rem;
	line-height: 1.95rem;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

blockquote p {
	margin-bottom: 0;
	margin-top: 0;
	font-size: inherit !important;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
}

/*horizontal ruler*/
.cms_divider,
.cms_item_divider,
.item_divider,
.separator,
#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	height: 1px !important;
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
	background-color: rgba(0, 0, 0, 0.15) !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

.collection > .node {
	border-top-color: rgba(0, 0, 0, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.section_separator {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

hr:not(.fc-divider):not(.formBreak) {
	border-color: rgba(0, 0, 0, 0.15);
	border: 0px;
	color: rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}

/*heading links*/
.left-sidebar-custom h1 a,
.main-content-column-nested h1 a,
.right-sidebar-column h1 a,
.left-sidebar-custom h2 a,
.main-content-column-nested h2 a,
.right-sidebar-column h2 a,
.left-sidebar-custom h3 a,
.main-content-column-nested h3 a,
.right-sidebar-column h3 a,
.left-sidebar-custom h4 a,
.main-content-column-nested h4 a,
.right-sidebar-column h4 a,
.left-sidebar-custom h5 a,
.main-content-column-nested h5 a,
.right-sidebar-column h5 a,
.left-sidebar-custom h6 a,
.main-content-column-nested h6 a,
.right-sidebar-column h6 a {
	color: inherit;
	font-weight: inherit;
	border-bottom: 1px solid transparent;
	line-height: 1.3em;
}

.left-sidebar-custom h1 a:hover,
.main-content-column-nested h1 a:hover,
.right-sidebar-column h1 a:hover,
.left-sidebar-custom h2 a:hover,
.main-content-column-nested h2 a:hover,
.right-sidebar-column h2 a:hover,
.left-sidebar-custom h3 a:hover,
.main-content-column-nested h3 a:hover,
.right-sidebar-column h3 a:hover,
.left-sidebar-custom h4 a:hover,
.main-content-column-nested h4 a:hover,
.right-sidebar-column h4 a:hover,
.left-sidebar-custom h5 a:hover,
.main-content-column-nested h5 a:hover,
.right-sidebar-column h5 a:hover,
.left-sidebar-custom h6 a:hover,
.main-content-column-nested h6 a:hover,
.right-sidebar-column h6 a:hover {
	border-bottom-color: rgba(28, 91, 162, 0.6);
	color: #009770;
}

/*module styling*/

/****************** General ************************/

/***************************************************/

/*dates*/
#events_module.cms_list .cms_date,
#news_module.cms_list article time,
#news_module.cms_entity .cms_date h3 {
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
	color: #636363;
	font-family: 'Open Sans', sans-serif;
}

/*paging*/
.pagination-wrapper .pagination-row td select#Page {
	padding: 4px;
}

@media (max-width: 767px) {
	.pagination-wrapper .pagination-row td {
		display: block;
		text-align: center;
	}
}

/****************** Accounts ************************/

/***************************************************/
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 300px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	margin: 20px 0;
	padding-bottom: 7px;
	font-family: 'Open Sans', sans-serif;
	color: #1c5ba2;
	font-size: 1.875rem;
	line-height: 2.125rem;
	font-weight: 700;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

/****************** Events *********************/

/***********************************************/
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .event-current-day {
	padding-top: 20px;
	overflow: hidden;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
	margin-bottom: 10px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_date {
	margin-bottom: 5px;
}

#events_module.cms_list .location-label-events-module {
	font-weight: 600;
}

#events_module.cms_list .list-view-bottom-padding {
	padding-bottom: 2px;
}

#events_module.cms_entity .cms_contact_name_label,
#events_module.cms_entity .cms_contact_phone_label,
#events_module.cms_entity .cms_location_label {
	font-weight: 600;
}

#events_module.cms_form .cms_info_req {
	float: none;
	text-align: right;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/****************** FAQs *********************/

/***********************************************/
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	list-style-type: disc;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_item .description,
#faqs_module.cms_list .cms_list_subitem .description {
	list-style-type: none;
	padding-top: 0;
}

#faqs_module .cms_list_item ul li.description p {
	margin-top: 0;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

.box.tools .content .contentbox_item form {
	margin-top: 15px;
}

.box.tools .content .contentbox_item form p {
	margin: 0;
}

/******************** GDPR *********************/

/***********************************************/
.consent-form-visible .header-section,
.consent-form-visible .at-share-dock.atss {
	z-index: 0;
}

.consent-form-visible #cookie-consent-form-container {
	display: flex !important;
}

#cookie-consent-form-container {
	justify-content: center;
	align-items: center;
	height: 100vh;
}

#cookie-consent-form-container form {
	top: 0;
}

#cookie-consent-container .consent-actions a.primary:hover {
	color: #1c5ba2 !important;
}

@media (max-width: 991px) {
	#cookie-consent-container {
		bottom: 3.5rem !important;
	}
}

/*************** Mailing Lists *****************/

/***********************************************/

/****************** News ***********************/

/***********************************************/
#news_module.cms_list article .description p {
	margin-top: 0;
	margin-bottom: 5px;
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list.header {
	margin-bottom: 15px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

.archive_links a,
.archive_links a.selected {
	padding-bottom: 0;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
	}
}

/**************** Newsletters ******************/

/***********************************************/
#newsletters_module.list .title h3 {
	margin-top: 0;
}

#newsletters_module.form .instructions {
	font-weight: 600;
}

#newsletters_module.form .required_message {
	margin-bottom: 20px;
}

#newsletters_module.form .form_item .field {
	padding-bottom: 15px;
}

@media (max-width: 991px) {
	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label,
	#newsletters_module.form .form_item .field {
		display: block;
		width: 100%;
		text-align: left;
		padding-left: 0;
		background-image: none;
	}

	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		padding-bottom: 5px;
		display: inline-block;
		width: auto;
	}

	#newsletters_module.form .form_item.required .label {
		background: url("/core/public/shared/assets/images/required.gif") no-repeat 100% 6px;
		padding-right: 20px;
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
		margin-top: 0;
	}

	#newsletters_module.form .form_buttons input {
		margin-top: 0;
	}
}

/***************** Resources *******************/

/***********************************************/
#moduleDocuments_module.cms_list .cms_item_description {
	margin-top: 10px;
}

.right-sidebar-column a.moduleDocuments-rss-feed-link {
	border: 0 none;
}

/*************** Submission Forms **************/

/***********************************************/
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding: 1px 27px;
	font-weight: 600;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field input.hasDatepicker[size="60"][type="text"] {
	display: inline-block;
	width: 100%;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form input.read-only,
#submissionforms_module.cms_form textarea.read-only {
	background-color: #e4e4e4;
}

/*confirmation page*/
.module-forms.confirmation .step-title {
	margin-top: 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #1c5ba2;
	font-size: 1.875rem;
	line-height: 2.125rem;
	font-weight: 700;
}

.module-forms.confirmation .value-cell img.signature-image {
	max-width: 100%;
}

@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}

	.module-forms.confirmation .label-cell {
		padding-bottom: 0;
	}

	.module-forms.confirmation .value-cell {
		padding-top: 0;
	}
}

/*dynamic content boxes*/

/*events*/
#events_module.homepage_contentbox:not(.conference-tabs) {
	text-align: center;
	margin-top: 1.5em;
}

#events_module.homepage_contentbox > br {
	display: none;
}

#events_module.homepage_contentbox .event_list + .event_list {
	margin-top: 1.5em;
	padding-top: 1.5em;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

#events_module.homepage_contentbox .event_list td {
	display: block;
	width: 100%;
}

#events_module.homepage_contentbox .event_list td.date {
	font-weight: 600;
}

#events_module.homepage_contentbox .event_list td.title div:not(.cms_description) {
	margin: 5px 0;
}

#events_module.homepage_contentbox .event_list td.title div:not(.cms_description) a {
	color: #1c5ba2;
	font-size: 1.5rem;
	line-height: 1.75rem;
	font-weight: 700;
	border-bottom: 1px solid transparent;
}

#events_module.homepage_contentbox .event_list td.title div:not(.cms_description) a:hover {
	border-bottom: 1px solid rgba(28, 91, 162, 0.6);
	color: #009770;
}

#events_module.homepage_contentbox .footer {
	display: none;
}

#events_module.homepage_contentbox.conference-tabs {
	margin-top: 1.5em;
}

#events_module.homepage_contentbox.conference-tabs .tab-heading-text {
	font-weight: 600;
	line-height: 2.4rem;
}

#events_module.homepage_contentbox.conference-tabs .conference-heading.tabbed {
	background-color: #1c5ba2;
	color: #fff;
	border-left: 2px solid #fff;
}

#events_module.homepage_contentbox.conference-tabs .conference-heading.tabbed.current {
	background-color: #ee7100;
	color: #fff;
	cursor: pointer;
}

#events_module.homepage_contentbox.conference-tabs .event-time {
	font-weight: 600;
}

#events_module.homepage_contentbox.conference-tabs .individual-event-time-wrapper {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

#events_module.homepage_contentbox.conference-tabs .time-description .event-title a {
	color: #1c5ba2;
	border-bottom: 1px solid transparent;
}

#events_module.homepage_contentbox.conference-tabs .time-description .event-title a:hover {
	border-bottom: 1px solid rgba(28, 91, 162, 0.6);
	color: #009770;
}

#events_module.homepage_contentbox.conference-tabs .event-time,
#events_module.homepage_contentbox.conference-tabs .time-description {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
}

@media (max-width: 767px) {
	#events_module.homepage_contentbox.conference-tabs .conference-heading.tabbed {
		border-bottom: 2px solid #fff;
		border-left: 0 none;
	}

	#events_module.homepage_contentbox.conference-tabs .event-time {
		padding-bottom: 0;
	}

	#events_module.homepage_contentbox.conference-tabs .time-description {
		padding-top: 0;
	}
}

/*news*/
#news_module.homepage_contentbox.displaymode_list .cms_list_item {
	text-align: center;
}

#news_module.homepage_contentbox.displaymode_list .cms_list_item + .cms_list_item {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	padding-top: 1.5em;
	margin-top: 1.5em;
}

#news_module.homepage_contentbox.displaymode_list .cms_list_item .cms_date h3 {
	color: #606060;
	font-size: 1.3rem;
	line-height: 1.3rem;
	font-weight: 600;
	text-align: center;
	margin: 5px 0;
}

#news_module.homepage_contentbox.displaymode_list > a {
	display: none;
}

/*submission forms*/
#submissionforms_module.homepage_contentbox {
	margin-top: 1.5em;
}

#submissionforms_module.homepage_contentbox .cms_divider {
	display: none;
}

#submissionforms_module.homepage_contentbox .cms_label {
	padding-bottom: 5px;
	font-size: 1rem;
	line-height: 1.25rem;
}

#submissionforms_module.homepage_contentbox label {
	font-size: 1rem;
	line-height: 1.25rem;
	display: inline-block;
}

#submissionforms_module.homepage_contentbox .ui-datepicker-trigger {
	vertical-align: baseline;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.homepage_contentbox .cms_field input[type="text"],
#submissionforms_module.homepage_contentbox .cms_field input[type="tel"],
#submissionforms_module.homepage_contentbox .cms_field input[type="password"],
#submissionforms_module.homepage_contentbox .cms_field input[type="email"],
#submissionforms_module.homepage_contentbox .cms_field input[type="date"],
#submissionforms_module.homepage_contentbox .cms_field select,
#submissionforms_module.homepage_contentbox .cms_field textarea {
	width: 100%;
}

#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .cms_hint {
	color: #636363;
	opacity: 1;
}

@media (max-width: 767px) {
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label label::after,
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label div::after {
		content: "";
		padding: 0;
	}
}
